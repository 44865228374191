import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const Team = createApi({
  reducerPath: "team",
  baseQuery,
  tagTypes: ["team"],
  endpoints: (builder) => ({
    allTeam: builder.query({
      query: (return_all) => ({
        url: "/installers/teams",
        method: "GET",
        // params: {
        //   return_all: return_all,
        // },
      }),
      providesTags: ["team"],
    }),

    deleteTeam: builder.mutation<any, any>({
      query: (id) => ({
        url: `/installers/teams/${id}/delete`,
        method: "POST",
      }),
      invalidatesTags: ["team"],
    }),
  }),
});

export const { useAllTeamQuery, useDeleteTeamMutation } = Team;
