import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { MyTextFiled } from "../standardSelect";
import styled from "styled-components";
import { MenuItem } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
type props = {
  // coordinates: { lat: number; lng: number };
  setCoordinates: any;
  address: any;
  setAddress: any;
  setAllLocationDetails?: any;
  setPostalCode?: any;
  setState?: any;
};
const MyGoogleMaps = ({
  // coordinates,
  setCoordinates,
  address,
  setAddress,
  setAllLocationDetails,
  setPostalCode,
  setState,
}: props) => {
  // console.log("Renderred");
  const [myCoordinates, setMyCoordinates] = React.useState<any>({
    lat: "",
    lng: "",
  });
  Geocode.setLocationType("ROOFTOP");
  Geocode.setApiKey("AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4");
  React.useEffect(() => {
    if (myCoordinates.lat) {
      Geocode.fromLatLng(myCoordinates.lat, myCoordinates.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let street, city, state, country, postalCode, route, county;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "street_number":
                  street = response.results[0].address_components[i].long_name;
                  break;
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "postal_code":
                  postalCode =
                    response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;

                case "route":
                  route = response.results[0].address_components[i].long_name;
                  break;

                case "administrative_area_level_2":
                  county = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          // Set the Normal states
          setPostalCode(postalCode);
          setState(state);

          // Not Normal
          // data.zip_code = postalCode;
          // data.street = route + " " + street;
          // data.city_name = city;
          // data.location = address;
          // data.county = county;
          // console.log("county", county);
          // console.log("city", city);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [myCoordinates]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4",
    libraries: ["places"],
  });
  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    setAllLocationDetails(results);
    setMyCoordinates(latLng);
  };
  if (!isLoaded) {
    return <p>loading...</p>;
  }
  return (
    <Bigsection>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <MyTextFiled
              sx={{ mb: 3 }}
              fullWidth
              {...getInputProps({
                placeholder: "Type Your address",
              })}
            ></MyTextFiled>

            <div className="result">
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion: any) => {
                const style: any = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                  textAlign: "center",
                  fontSize: "13px",
                };

                return (
                  <MenuItem
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </MenuItem>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .result {
    box-shadow: 5px 5px 5px #a6a6a6;
    width: 100%;
  }
`;
export default React.memo(MyGoogleMaps);
