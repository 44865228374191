import React from "react";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import moment from "moment";
// import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop, {
  withDragAndDropProps,
} from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withGard from "../../../utils/withGard";
import AddEventForm from "../addEventForm";
import styled from "styled-components";
import { MyAddIcon, MyExpandMoreIcon } from "../../common/icons";
import { AddButton } from "../../common/Buttons/AddButton";
import { useGetEventQuery } from "../../../store/RTK/calender";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Grid } from "@mui/material";
interface MyEvent {
  title: string;
  start: Date;
  end: Date;
}
type event = {
  created_at: string;
  customer_address: string;
  customer_name: string;
  deleted_at: null;
  description: string;
  from_date: string;
  from_time: string;
  id: number;
  order_id: number;
  status: string;
  title: string;
  to_date: string;
  to_time: string;
  type: string;
  updated_at: string;
  user_id: number;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyCalendar = () => {
  const { data, isLoading } = useGetEventQuery({});
  const [eventss, setEvents] = React.useState<Event[]>(data?.data || []);
  const [singleEvent, setSingleEvent] = React.useState<event>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  React.useEffect(() => {
    const newEvents = data?.data?.map((e: any) => {
      return {
        ...e,
        start: new Date(Date.parse(e.from_date)),
        end: new Date(e.to_date),
        ss: "ss",
      };
    });
    setEvents(newEvents);
  }, [data?.data]);
  const [open, setOpen] = React.useState<boolean>(false);
  moment.locale("en-us");
  const localizer = momentLocalizer(moment);

  const onEventResize: withDragAndDropProps["onEventResize"] = (eventss) => {
    const { start, end } = data;
    setEvents((currentEvents) => {
      const firstEvent = {
        start: new Date(start),
        end: new Date(end),
      };
      return [...currentEvents, firstEvent];
    });
  };

  const onEventDrop: withDragAndDropProps["onEventDrop"] = (data) => {
    console.log(data);
  };
  const usaDate = (date: any) => {
    console.log("date", typeof date);
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  return (
    <>
      <Bigsection>
        <div className="create-button">
          <AddButton
            startIcon={<MyAddIcon />}
            endIcon={<MyExpandMoreIcon />}
            onClick={() => setOpen(!open)}
          >
            Create Event
          </AddButton>
        </div>
        <DnDCalendar
          localizer={localizer}
          events={eventss}
          // onEventDrop={onEventDrop}
          // onEventResize={onEventResize}
          // resizable
          style={{ height: 500, backgroundColor: "white" }}
          // draggableAccessor={(eventss) => true}
          onSelectEvent={async (e: any) => {
            await setSingleEvent(e);
            await handleClickOpen();
          }}
        />
        <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <p
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {" "}
              Title:{" "}
              <span
                style={{
                  fontSize: "14px",
                  color: "#272727",
                  fontWeight: "400",
                }}
              >
                {singleEvent?.title}
              </span>
            </p>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-slide-description"> */}
            <Grid container columns={12} spacing={1}>
              <Grid item xs={12} md={6}>
                <p
                  className="header"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Customer name:{" "}
                  <span
                    className="desc"
                    style={{
                      fontSize: "14px",
                      color: "#272727",
                      fontWeight: "400",
                    }}
                  >
                    {singleEvent?.customer_name}
                  </span>
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className="header"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Type:{" "}
                  <span
                    className="desc"
                    style={{
                      fontSize: "14px",
                      color: "#272727",
                      fontWeight: "400",
                    }}
                  >
                    {singleEvent?.type}
                  </span>
                </p>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <p
                  className="header"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Customer address:
                  <span
                    className="desc"
                    style={{
                      fontSize: "14px",
                      color: "#272727",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    {singleEvent?.customer_address}
                  </span>
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className="header"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  From :{" "}
                  <span
                    className="desc"
                    style={{
                      fontSize: "14px",
                      color: "#272727",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    {usaDate(singleEvent?.from_date)}{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "bold",
                        padding: "0 7px",
                      }}
                    >
                      To :
                    </span>
                    {usaDate(singleEvent?.to_date)}
                  </span>
                </p>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <p
                  className="header"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Description:{" "}
                  <span
                    className="desc"
                    style={{
                      fontSize: "14px",
                      color: "#272727",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    {singleEvent?.description}
                  </span>
                </p>
              </Grid>
            </Grid>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            {/* <button onClick={handleClose}>Disagree</button> */}
            {/* <button onClick={handleClose}>Agree</button> */}
          </DialogActions>
        </Dialog>
      </Bigsection>
      <AddEventForm open={open} setOpen={setOpen}></AddEventForm>
    </>
  );
};
const Bigsection = styled.div`
  .create-button {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
  }
  .kspHcu .rbc-event {
    color: white;
  }
  .rbc-agenda-event-cell {
    cursor: pointer;
  }
`;

const DnDCalendar = withDragAndDrop(Calendar);

export default MyCalendar;
