import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const Activities = createApi({
  reducerPath: "Activities",
  baseQuery,
  tagTypes: ["Activities"],
  endpoints: (builder) => ({
    allActivities: builder.query({
      query: (data) => ({
        url: `/working-orders/activities/${data.id}`,
        method: "GET",
        params: {
          return_all: data.return_all,
        },
      }),
      providesTags: ["Activities"],
    }),
    createActivity: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `/working-orders/activities/${id}/create-activity`,
        method: "POST",
        body: data,
        formData: true,
      }),

      invalidatesTags: ["Activities"],
    }),
  }),
});

export const { useCreateActivityMutation, useAllActivitiesQuery } = Activities;
