// FileUpload.tsx
import React, { useEffect, useState } from "react";
import { useFormContext, Controller, FieldValues } from "react-hook-form";
import { Box, Button, Input, Typography } from "@mui/material";
import styled from "styled-components";
import { CloudDownloadOutlined } from "@mui/icons-material";

interface FileUploadProps {
  name: string;
  label: string;
  accept: string;
  multiple?: boolean;
  defaultValue?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  label,
  accept,
  multiple,
  defaultValue,
}) => {
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );

  const { control } = useFormContext();

  useEffect(() => {
    if (defaultValue && !multiple) {
      setImagePreview(defaultValue as string);
    }
  }, [defaultValue, multiple]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue && multiple ? [defaultValue] || [] : defaultValue || ""
      }
      // defaultValue={defaultValue || (multiple ? [] : "")}
      render={({ field }) => (
        <>
          <input
            accept={accept}
            id={name}
            type="file"
            multiple={multiple}
            style={{ display: "none" }}
            onChange={(e: any) => {
              const files = multiple ? [...e.target.files] : e.target.files[0];
              field.onChange(files);

              if (!multiple) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setImagePreview(reader.result as string);
                };
                reader.readAsDataURL(files);
              }
            }}
          />
          <label htmlFor={name}>
            <BoxUploadLogo>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DropIcon>
                  <CloudDownloadOutlined />
                </DropIcon>
                <Box>
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color="var(--primary-color)"
                  >
                    {label}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={300}
                    color="var(--primary-color)"
                  >
                    Drop your file here or browse
                  </Typography>
                </Box>
              </Box>
            </BoxUploadLogo>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            )}
          </label>
          <Typography variant="body2" color="textSecondary">
            {multiple
              ? `${(field.value as File[]).length} files selected`
              : (field.value as File).name}
          </Typography>
        </>
      )}
    />
  );
};

const BoxUploadLogo = styled(Box)`
  && {
    border: 2px dashed var(--primary-color);
    border-radius: 1rem;
    padding: 1.5rem 2.5rem;
    background: #f5f9f280;
    margin: 10px 0;
  }
`;

const DropIcon = styled(Box)`
  && {
    width: 56px;
    height: 56px;
    background: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    svg {
      font-size: 30px;
      color: var(--primary-color);
    }
  }
`;

export default FileUpload;
