import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Table,
} from "@mui/material";
import styled from "styled-components";
import MyCard from "./Card";

type TProps = {
  data: any;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
};

const ServiceTable = ({ data, isLoading, page, setPage }: TProps) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <div className="seconddiv">
      {isLoading ? (
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "200px",
            px: 15,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2} columns={12}>
            {data?.data?.data?.length > 0
              ? data?.data?.data?.map((item: any) => (
                  <Grid item xs={12} md={4}>
                    <MyCard data={item} />
                  </Grid>
                ))
              : null}
          </Grid>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: 5,
            }}
          >
            <Pagination
              count={Math.ceil(data?.data?.total / 15)}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </>
      )}
    </div>
  );
};

const MyTable = styled(Table)`
  && {
  }
  th {
    &:nth-child(2) {
      text-align: left;
    }
  }
  td {
    &:nth-child(2) {
      text-align: left;
    }
  }
`;
export default ServiceTable;
