import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import SlidingPane from "../../../common/slidingPane";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import "react-datepicker/dist/react-datepicker.css";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  collection: any;
  setCollection: any;
};
const Filter = ({ open, setOpen, collection, setCollection }: props) => {
  const [dateRange, setDateRange] = React.useState([null, null]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollection(event.target.value);
  };

  return (
    <Bigsection>
      <SlidingPane open={open} setOpen={setOpen}>
        <Grid container columns={12}>
          <Grid item xs={12} className="line">
            <h3>Reports collection filter</h3>
          </Grid>
          <Grid item xs={12} my={3}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Filter</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={collection}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="All"
                  control={<Radio size="small" />}
                  label="All"
                />
                <FormControlLabel
                  value="not_eligible"
                  control={<Radio size="small" />}
                  label="Not Eligible"
                />
                <FormControlLabel
                  value="pending"
                  control={<Radio size="small" />}
                  label="Pending"
                />
                <FormControlLabel
                  value="approved"
                  control={<Radio size="small" />}
                  label="Approved"
                />
                <FormControlLabel
                  value="declined"
                  control={<Radio size="small" />}
                  label="Declined"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/* <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              // onChange={(update) => {
              //   setDateRange(update);
              // }}
              isClearable={true}
            /> */}
          </Grid>
        </Grid>
      </SlidingPane>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  background: #ffffff !important;
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #00818a;
  }
  .css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: #101828;
  }
`;
export default Filter;
