import React, { useEffect, useState } from "react";
import SlidingPane from "../../../../common/slidingPane";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useCreateActivityMutation } from "../../../../../store/RTK/profile/activities";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItem,
  List,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Description } from "@mui/icons-material";
import { Table } from "../../../../../style/TableStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StanderInput from "../../../../common/standerInput";
import { Tlogin } from "../../../../../typs/loginType";
import { AddButton } from "../../../../common/Buttons/AddButton";
import { object } from "yup";

// type any = {
//   openUpload?: boolean,
//   setOpenUploadd?: React.Dispatch<React.SetStateAction<boolean>>,
// };
const UploadActivities = ({ openUpload, setOpenUploadd, id }) => {
  const formData = new FormData();
  const [errors, setError] = React.useState("");
  const [showUploadOf_activitiesMedia, setShowUploadOf_activitiesMedia] =
    useState(false);
  const [
    UpdatedUploadFilesOf_activitiesMedia,
    setUpdatedUploadedFilesOf_activitiesMedia,
  ] = useState([]);
  const [fileLimitOf_activitiesMedia, setFileLimitOf_activitiesMedia] =
    useState(false);

  const [uploadedFilesOf_activitiesMedia, setUploadedFilesOf_activitiesMedia] =
    useState([]);
  const [state, setState] = useState({
    description: "",
  });
  const [createActivity, { isLoading, isError, isSuccess }] =
    useCreateActivityMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     console.log(state);
  //   };
  const handleSubmit = async (e) => {
    console.log(state.description);
    e.preventDefault();

    try {
      // formData.append("id",id);
      formData.append("description", state.description);
      UpdatedUploadFilesOf_activitiesMedia.map((e, i) => {
        formData.append(`media[${i}]`, e);
      });
      const body = {
        id: id,
        data: formData,
      };
      await createActivity(body).unwrap();
      if (!isError && !isLoading && isSuccess) {
        toast.success("License has been created");
      } else {
      }
    } catch (error) {
      setError(error);
    }
  };

  const MAX_COUNT = 500;

  const handleShowUploadFilesOf_activitiesMedia = () => {
    setShowUploadOf_activitiesMedia(true);
  };

  const handlecloseUploadOf_activitiesMedia = () => {
    setShowUploadOf_activitiesMedia(false);
  };

  //handle Upload  activitiesMedia  Files
  const handleUploadFilesOf_activitiesMedia = (files) => {
    const uploaded = [...UpdatedUploadFilesOf_activitiesMedia];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFilesOf_activitiesMedia(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimitOf_activitiesMedia(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimitOf_activitiesMedia(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFilesOf_activitiesMedia(uploaded);
    }
  };

  // handle file event of activitiesMedia media
  const handleFileEventOfactivitiesMedia = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFilesOf_activitiesMedia(chosenFiles);
  };

  const handleRemoveItemOf_activitiesMedia = (file) => {
    const name = UpdatedUploadFilesOf_activitiesMedia.map((file) => file.name);
    setUpdatedUploadedFilesOf_activitiesMedia(
      UpdatedUploadFilesOf_activitiesMedia.filter(
        (item) => item.name !== file.name
      )
    );
    console.log(name, "name");
    console.log(
      UpdatedUploadFilesOf_activitiesMedia,
      "UpdatedUploadFilesssssssss"
    );
  };

  return (
    <Bigsection>
      <SlidingPane open={openUpload} setOpen={setOpenUploadd} big={true}>
        <Grid container columns={12}>
          <Grid item xs={12} className="line">
            <h3>upload activities </h3>
          </Grid>

          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
          <IconButton onClick={() => handleShowUploadFilesOf_activitiesMedia()}>
            <Typography>
              <span style={{ color: "black", fontWeight: "bold" }}>
                Upload Files
              </span>{" "}
            </Typography>{" "}
            {!showUploadOf_activitiesMedia && (
              <CloudUploadIcon style={{ marginLeft: "30px" }} />
            )}
          </IconButton>
          {showUploadOf_activitiesMedia && (
            <IconButton onClick={handlecloseUploadOf_activitiesMedia}>
              <CloseIcon />
            </IconButton>
          )}

          {showUploadOf_activitiesMedia && (
            <Grid xs={12}>
              <Grid className="display" mt={4}>
                <FormControl variant="outlined">
                  <label htmlFor="contained-button-fileofactivitiesMedia">
                    <Input
                      // accept="image/*"
                      accept="image/jpeg , image/jpg , image/png"
                      id="contained-button-fileofactivitiesMedia"
                      multiple
                      type="file"
                      onChange={handleFileEventOfactivitiesMedia}
                    />
                    <Button component="span" className="btn_upload">
                      <span className="icon">
                        <Add />
                      </span>
                      <span className="title"> Upload Files</span>
                    </Button>
                  </label>
                </FormControl>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 460,
                    marginTop: "30px",
                    display: "inline-block",
                  }}
                >
                  {UpdatedUploadFilesOf_activitiesMedia.map((file, index) => (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            handleRemoveItemOf_activitiesMedia(file)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar style={{ display: "flex" }}>
                        <FolderIcon />
                        <Typography>{file.name}</Typography>{" "}
                      </ListItemAvatar>
                      <ListItemText />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
          <Grid xs={12}>
            <Grid className="display">
              <form onSubmit={handleSubmit}>
                <div className="form-control" style={{ margin: "10px" }}>
                  {/* <label>description</label> */}
                  {/* <input
                type="text"
                name="description"
                value={state.description}
                onChange={handleInputChange}
              /> */}
                  <TextField
                    name="description"
                    value={state.description}
                    onChange={handleInputChange}
                    label="description"
                  />
                </div>

                <div className="form-control">
                  {/* <button type="submit">Login</button> */}
                  <Grid item xs={12}>
                    <AddButton type="submit" fullWidth>
                      save
                    </AddButton>
                  </Grid>
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </SlidingPane>
    </Bigsection>
  );
};
const Input = styled.input`
  display: none;
`;
const Bigsection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    max-width: 550px;
    width: 550px;
    margin-top: 25px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
    p {
      display: flex;
      align-items: center;
      margin-right: 15px;
      color: #99a5b9;
      background: #fafcfe;
      &:first-child {
        color: #1e7d59;
        background: #eaf6f1;
        border-radius: 4px;
        padding: 0px 8px;
      }
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b0cacc;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #333333;
    }
  }
  .infoContainer {
    .first_div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      div:nth-child(2) {
        margin-left: 1rem;
        p {
          display: flex;
          /* align-items: flex-end; */
          /* justify-content: flex-start; */
          margin-right: 18px;
          span {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
        }
        svg {
          color: #7a8494;
          margin-right: 8px;
        }
      }
    }
    .seconddiv {
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
        border: 1px solid #eeeff1;
        border-radius: 8px;
        padding: 1rem;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        width: 100%;
      }
      td {
        p {
          color: #344054;
          font-size: 14px;
        }
        .header {
          color: #7a8494;
          font-size: 14px;
        }
        svg {
          height: 13.29px;
          width: 13.29px;
          color: #474d56;
          margin-right: 12px;
        }
      }
    }
  }
  .nav-list {
    /* margin: 1.5rem 0; */
    ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
      position: relative;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #667085;
        position: relative;
        p {
          padding: 0.5rem;
        }

        a.active {
          p {
            background: #eaf6f1;
            color: #1e7d59;
          }
          &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            background: #1e7d59;
            height: 3px;
            width: 100%;
            z-index: 2;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background: #e4e7ec;
        height: 3px;
        width: 100%;
      }
    }
  }
  .nav-content {
    min-height: 10dvh;
  }
  .maps {
    width: 100%;
    height: 100%;
  }
`;
export default UploadActivities;
