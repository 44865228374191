import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MAIN_URL } from "../../config/Api";

const baseQuery = fetchBaseQuery({
  baseUrl: MAIN_URL,
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body,
      }),
    }),

    register: builder.mutation<any, any>({
      query: (body) => ({
        url: "auth/register",
        method: "POST",
        body,
      }),
    }),

    categories: builder.query<any, any>({
      query: () => ({
        url: "/categories",
        method: "GET",
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useCategoriesQuery } =
  authApi;
