import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  data: any | null;
}

const data = localStorage.getItem("data")
  ? JSON.parse(localStorage.getItem("data") || "")
  : null;

const initialState: AuthState = {
  data: data,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state) => {
      state.data = localStorage.getItem("data")
        ? JSON.parse(localStorage.getItem("data") || "")
        : null;
    },
    logOut: (state) => {
      state.data = null;
      localStorage.removeItem("data");
    },
  },
});

export const { loginSuccess, logOut } = authSlice.actions;

export default authSlice.reducer;
