import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import UploadActivities from "../activities/uploadActivities";
import { useParams } from "react-router-dom";
import { useAllActivitiesQuery } from "../../../../store/RTK/profile/activities";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ActivityStyle } from "./activitesStyle";

type activity = {
  id: number;
  path: string;
  description: string;
  media: [];
};

const Activities = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useAllActivitiesQuery({
    return_all: "1",
    id: id,
  });
  const [openUpload, setOpenUpload] = React.useState<boolean>(false);
  const handleOpenUploadModal = () => {
    setOpenUpload(!openUpload);
    console.log("open moda");
  };
  return (
    <ActivityStyle>
      {isLoading ? (
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "200px",
            px: 15,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <List>
              {data?.data?.length > 0 ? (
                data?.data?.map((e: activity) => (
                  <>
                    <p>{e.description}</p>
                    <a href={e.path} target="_blank">
                      {e.media.length > 0 ? (
                        e.media.map((m: any, index) => (
                          <li key={m.id}>
                            <a href={m.path} target="_blank">
                              {++index}: see image
                            </a>
                          </li>
                          // <ListItem key={e.id}>
                          //   <ListItemAvatar>
                          //     <Avatar>
                          //       <FolderIcon />
                          //     </Avatar>
                          //   </ListItemAvatar>
                          //   <ListItemText primary="View File" />
                          // </ListItem>
                        ))
                      ) : (
                        <p>no uploaded files</p>
                      )}
                    </a>
                    <hr></hr>
                  </>
                ))
              ) : (
                <ListItem> no data </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <main className="msger-chat">
              <div className="msg left-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">BOT</div>
                    <div className="msg-info-time">12:45</div>
                  </div>

                  <div className="msg-text">
                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                    😄
                  </div>
                </div>
              </div>

              <div className="msg right-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">Sajad</div>
                    <div className="msg-info-time">12:46</div>
                  </div>

                  <div className="msg-text">
                    You can change your name in JS section!
                  </div>
                </div>
              </div>
              <div className="msg left-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">BOT</div>
                    <div className="msg-info-time">12:45</div>
                  </div>

                  <div className="msg-text">
                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                    😄
                  </div>
                </div>
              </div>

              <div className="msg right-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">Sajad</div>
                    <div className="msg-info-time">12:46</div>
                  </div>

                  <div className="msg-text">
                    You can change your name in JS section!
                  </div>
                </div>
              </div>
              <div className="msg left-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">BOT</div>
                    <div className="msg-info-time">12:45</div>
                  </div>

                  <div className="msg-text">
                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                    😄
                  </div>
                </div>
              </div>

              <div className="msg right-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">Sajad</div>
                    <div className="msg-info-time">12:46</div>
                  </div>

                  <div className="msg-text">
                    You can change your name in JS section!
                  </div>
                </div>
              </div>
              <div className="msg left-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">BOT</div>
                    <div className="msg-info-time">12:45</div>
                  </div>

                  <div className="msg-text">
                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                    😄
                  </div>
                </div>
              </div>

              <div className="msg right-msg">
                <div
                  className="msg-img"
                  // style="background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg)"
                ></div>

                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">Sajad</div>
                    <div className="msg-info-time">12:46</div>
                  </div>

                  <div className="msg-text">
                    You can change your name in JS section!
                  </div>
                </div>
              </div>
            </main>
          </Grid>
        </Grid>
      )}

      <IconButton onClick={handleOpenUploadModal}>
        <CloudUploadIcon />
      </IconButton>
      {openUpload && (
        <UploadActivities
          openUpload={openUpload}
          setOpenUploadd={setOpenUpload}
          id={id}
        />
      )}
    </ActivityStyle>
  );
};

export default Activities;
