import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const jobOrderApi = createApi({
  reducerPath: "orders",
  baseQuery,
  tagTypes: ["orders", "sigleOrder"],
  endpoints: (builder) => ({
    //withPagenate
    getJobOrder: builder.query({
      query: ({ page, status, approval, collection_status, return_all }) => ({
        url: "/working-orders",
        method: "get",
        params: {
          page: page,
          status,
          approval,
          collection_status,
          return_all,
        },
      }),
      providesTags: ["orders"],
    }),

    //withPagenate
    getSingleJobOrder: builder.query({
      query: (id) => ({
        url: `/working-orders/${id}`,
        method: "get",
      }),
      providesTags: ["orders", "sigleOrder"],
    }),

    approveJobOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `working-orders/${id}/approval`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sigleOrder"],
    }),

    requestPayment: builder.mutation({
      query: (id) => ({
        url: `working-orders/${id}/request-payment`,
        method: "POST",
      }),
      invalidatesTags: ["sigleOrder"],
    }),
    editAdder: builder.mutation({
      query: ({ id, data }) => ({
        url: `working-orders/${id}/edit-request`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["sigleOrder"],
    }),
    getSystemInfoOfJobOrder: builder.query({
      query: (id) => ({
        url: `/working-orders/system-infos/${id}`,
        method: "get",
      }),
      providesTags: ["orders", "sigleOrder"],
    }),

    getAttachmentsOfJobOrder: builder.query({
      query: (id) => ({
        url: `/working-orders/${id}/attachments`,
        method: "get",
      }),
      providesTags: ["orders", "sigleOrder"],
    }),

    getAddersOfJobOrder: builder.query({
      query: (id) => ({
        url: `/working-orders/adders/${id}`,
        method: "get",
      }),
      providesTags: ["orders", "sigleOrder"],
    }),
  }),
});

export const {
  useGetJobOrderQuery,
  useGetSingleJobOrderQuery,
  useLazyGetSingleJobOrderQuery,
  useApproveJobOrderMutation,
  useRequestPaymentMutation,
  useGetSystemInfoOfJobOrderQuery,
  useGetAttachmentsOfJobOrderQuery,
  useGetAddersOfJobOrderQuery,
  useEditAdderMutation
} = jobOrderApi;
