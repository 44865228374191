import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Table } from "../../../style/TableStyle";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import AddPayment from "./addPayment";

const Payment = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handlChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <div className="container">
            <div className="firstdiv">
              <div>
                <h4>Collection</h4>
              </div>
              <div>
                <AddButton startIcon={<MyAddIcon />} onClick={handlChange}>
                  Add Bank ACCOUNT
                </AddButton>
              </div>
            </div>
            <Grid item xs={12}>
              <div className="seconddiv">
                <Table>
                  <tr>
                    <th>Full Name</th>
                    <th>Bank name</th>
                    <th>Branch address </th>
                    <th>Account number</th>
                    <th>Bank code</th>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="success">
                        <p>
                          <span className="dot"></span> collected
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="success">
                        <p>
                          <span className="dot"></span> collected
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="success">
                        <p>
                          <span className="dot"></span> collected
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="pending">
                        <p>
                          <span className="dot"></span> Pending
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="pending">
                        <p>
                          <span className="dot"></span> Pending
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                  <tr>
                    <td>Guy Hawkins</td>
                    <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                    <td>
                      <div className="pending">
                        <p>
                          <span className="dot"></span> Pending
                        </p>
                      </div>
                    </td>
                    <td>Earning</td>
                    <td>Date</td>
                  </tr>
                </Table>
              </div>
            </Grid>
          </div>
        </Grid>
      </Bigsection>
      <AddPayment open={open} setOpen={setOpen} />
    </>
  );
};
const Bigsection = styled.div`
  .container {
    width: 100%;
  }
  .seconddiv {
    /* min-height: 60dvh; */
  }
`;
export default Payment;
