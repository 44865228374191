import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const company = createApi({
  reducerPath: "companies",
  baseQuery,
  tagTypes: ["companies"],
  endpoints: (builder) => ({
    allCompanies: builder.query({
      query: () => ({
        url: "/company-profiles",
        method: "GET",
        // params: {
        //   return_all: return_all,
        // },
      }),
      providesTags: ["companies"],
    }),
    createCompany: builder.mutation<{}, FormData>({
      query: (body) => ({
        url: "/company-profiles/update-create",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["companies"],
    }),
    deleteCompany: builder.mutation<any, any>({
      query: (id) => ({
        url: `/installers/licenses/${id}/delete`,
        method: "POST",
      }),
      invalidatesTags: ["companies"],
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useAllCompaniesQuery,
} = company;
