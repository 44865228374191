import React from "react";
import MyModal from "../../../common/Modal";
import styled from "styled-components";

import { CircularProgress, Grid } from "@mui/material";
import StanderInput from "../../../common/standerInput";
import SelectInput from "../../../common/SelectInput/SelectInput";
import {
  useEditSingleAdderMutation,
  useGetAllCategoriesChildQuery,
} from "../../../../store/RTK/adders";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SubmitButton } from "../../../common/Buttons/SubmitButton";
import { toast } from "react-toastify";
import {
  allPer,
  allPrice,
  operatorsList,
  panelsList,
} from "../../../../constant/List";
import { BiDollar } from "react-icons/bi";
type props = {
  open?: any;
  setOpen?: any;
  selectedAdder: {
    id: number;
    name: number;
    price: string;
    per: string;
    description: string;
    expiration_date: string;
    category: string;
    adder_category_id: string;
    price_type: string;
    parent_category_id: string;
    operator: string;
    condition_on: number | string;
    condition_number: number | string;
  };
};

const EditAdder: React.FC<props> = ({
  open,
  setOpen,
  selectedAdder,
}: props) => {
  //get all category

  const {
    data: allCategories,
    isLoading,
    isFetching,
  } = useGetAllCategoriesChildQuery(
    {
      parent_id: selectedAdder?.parent_category_id,
    },
    {
      skip: !selectedAdder?.parent_category_id,
    }
  );

  const [editSingleAdder, { isLoading: loading }] =
    useEditSingleAdderMutation();
  const methods = useForm<any>({});

  const onSubmit: SubmitHandler<any> = async (data) => {
    let id = selectedAdder?.id;
    await editSingleAdder({ id, data })
      .unwrap()
      .then((res) => {
        toast.success("The Adder Edited Successfully");
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Something Went wrong");
      });
  };

  return (
    <Bigsection>
      <MyModal open={open} setOpen={setOpen}>
        <h5>You are editting this adder </h5>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {isLoading || isFetching ? (
              <CircularProgress />
            ) : (
              <Grid textAlign={"left"} container columns={12} spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <SelectInput
                    name={`adder_category_id`}
                    label=" Adder Category"
                    data={allCategories?.data}
                    required
                    defaultValue={selectedAdder?.adder_category_id}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <SelectInput
                    data={operatorsList}
                    name={`operator`}
                    label="operator"
                    defaultValue={selectedAdder?.operator}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SelectInput
                    data={panelsList}
                    name={`condition_on`}
                    label="condition_on"
                    defaultValue={selectedAdder?.condition_on}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <StanderInput
                    name="condition_number"
                    Label="condition number"
                    required
                    defaultValue={selectedAdder?.condition_number}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <StanderInput
                    required
                    name={`price`}
                    Label="Price"
                    type="number"
                    startIcon={<BiDollar />}
                    defaultValue={selectedAdder?.price}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <SelectInput
                    name={`price_type`}
                    label="Price Type"
                    data={allPrice}
                    required
                    defaultValue={selectedAdder?.price_type}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <SelectInput
                    name="per"
                    label="per"
                    data={allPer}
                    required
                    defaultValue={selectedAdder?.per}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SubmitButton
                    type="submit"
                    fullWidth
                    sx={{ margin: "1rem 0" }}
                  >
                    {loading ? " Loading...." : "Edit"}
                  </SubmitButton>
                </Grid>
              </Grid>
            )}
          </form>
        </FormProvider>
      </MyModal>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .buttons {
    display: flex;
  }
`;
export default EditAdder;
