import { Box, Button, Grid, IconButton } from "@mui/material";
import SelectInput from "../../../common/SelectInput/SelectInput";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import StanderInput from "../../../common/standerInput";
import { ControlPoint } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import {
  allPer,
  allPrice,
  operatorsList,
  panelsList,
  priceTyps,
} from "../../../../constant/List";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiDollar } from "react-icons/bi";
import { useCreateAdderMutation } from "../../../../store/RTK/adders";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const schema = Yup.object({
  adders: Yup.array(
    Yup.object({
      adder_category_id: Yup.string().required("Category is required "),
      operator: Yup.string().required("Operator is required "),
      condition_on: Yup.string().required("condition is required "),
      is_operator: Yup.number().nullable(),
      condition_number: Yup.number()
        .required("condition number is required ")
        .typeError("number is required "),
      price: Yup.number()
        .required("price is required ")
        .typeError("price is required "),
      price_type: Yup.string().required("price type is required "),
      per: Yup.string().required("pre price type is required "),
      price_info: Yup.string().required("price info is required "),
    })
  ),
});

const DaynamicForm = ({ data }: { data: any[] }) => {
  const { id } = useParams();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [addAdder, { isLoading, isSuccess }] = useCreateAdderMutation();

  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "adders",
  });
  const onSubmit = async (data: any) => {
    const formData = new FormData();

    data?.adders.map((adder: any, index: any) => {
      for (const property in adder) {
        formData.append(`adders[${index}][${property}]`, adder[property]);
      }
      formData.append(`adders[${index}][parent_category_id]`, `${id}`);
    });

    await addAdder(formData)
      .unwrap()
      .then(() => toast.success("Adder created Successfully"))
      .catch((err) => toast.error("something went wrong "));
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <Grid
              key={field.id}
              container
              spacing={2}
              columns={16}
              alignItems="center"
            >
              <Grid item xs={12} md={2}>
                <SelectInput
                  required
                  data={data}
                  name={`adders[${index}].adder_category_id`}
                  label="Adder category"
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <SelectInput
                  data={[
                    { id: 0, name: "no" },
                    { id: 1, name: "yes" },
                  ]}
                  name={`adders[${index}].is_operator`}
                  label="is operator"
                />
              </Grid>
              {methods.watch(
                `adders[${index}].is_operator` as `adders.${number}.is_operator`
              ) === 1 && (
                <>
                  <Grid item xs={12} md={2}>
                    <SelectInput
                      data={operatorsList}
                      name={`adders[${index}].operator`}
                      label="operator"
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5}>
                    <SelectInput
                      data={panelsList}
                      name={`adders[${index}].condition_on`}
                      label="condition on"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <StanderInput
                      required
                      name={`adders[${index}].condition_number`}
                      Label="Condition Number"
                    />
                  </Grid>{" "}
                </>
              )}
              <Grid item xs={12} md={1.5}>
                <StanderInput
                  required
                  name={`adders[${index}].price`}
                  Label="Price"
                  type="number"
                  startIcon={<BiDollar />}
                />
              </Grid>{" "}
              <Grid item xs={12} md={1.5}>
                <SelectInput
                  data={priceTyps}
                  name={`adders[${index}].price_info`}
                  label="Price Info"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SelectInput
                  required
                  data={allPrice}
                  name={`adders[${index}].price_type`}
                  label="price type"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SelectInput
                  required
                  data={allPer}
                  name={`adders[${index}].per`}
                  label="Per"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
                  <Delete sx={{ fontSize: 30, color: "#fe5e5e" }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Box>
            <Button
              startIcon={<ControlPoint />}
              onClick={() =>
                append({
                  adder_category_id: "",
                  price_info: "",
                  is_operator: 0,
                  condition_number: 1,
                  condition_on: "none",
                  operator: "equal",
                  per: "",
                  price_type: "",
                  price: 1,
                })
              }
              sx={{
                color: "#00818A",
                mt: 2,
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Add New Adder
            </Button>
          </Box>

          {fields?.length ? (
            <Box sx={{ width: { xs: "100%", md: "50%" }, mx: "auto", my: 5 }}>
              <Button
                type="submit"
                sx={{
                  width: "100%",
                  bgcolor: "#00818A",
                  borderRadius: "1rem",
                  py: 1,
                }}
                variant="contained"
              >
                {isLoading ? "loading ... " : "Save Adder"}
              </Button>
            </Box>
          ) : null}
        </form>
      </FormProvider>
    </Box>
  );
};

export default DaynamicForm;
