import React from "react";
import SlidingPane from "../../common/slidingPane";
import Grid from "@mui/material/Grid";
import { Table } from "../../../style/TableStyle";
import {
  MyAddIcon,
  MyAiOutlinePlus,
  MyBiCheck,
  MyRiDeleteBinLine,
} from "../../common/icons";
import styled from "styled-components";
import { AddButton } from "../../common/Buttons/AddButton";
import StanderInput from "../../common/standerInput";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Tlogin } from "../../../typs/loginType";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const ChangeWorkorder = ({ open, setOpen }: props) => {
  const methods = useForm<any>({ mode: "onChange" });
  const onSubmit: SubmitHandler<Tlogin> = (data) => console.log(data);
  return (
    <Bigsection>
      {" "}
      <SlidingPane open={open} setOpen={setOpen} big={true}>
        <Grid container columns={12}>
          <Grid item xs={12} className="line">
            <h3>Change Work order</h3>
          </Grid>
          <div className="space"></div>
          <div className="container">
            <Grid item xs={12}>
              <div className="firstdiv">
                <div>
                  <h4>Working Days Info</h4>
                </div>
                <div>
                  <AddButton startIcon={<MyAddIcon />}>
                    Create Adders rate
                  </AddButton>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="seconddiv">
                <Table>
                  <tr>
                    <th>Price</th>
                    <th>Adders</th>
                    <th>Actions</th>
                  </tr>
                  <tr>
                    <td>$130</td>
                    <td>MPU 175</td>
                    <td className="icon">
                      <MyRiDeleteBinLine />
                      <MyAiOutlinePlus />
                    </td>
                  </tr>
                  <tr>
                    <td>$130</td>
                    <td>MPU 175</td>
                    <td className="icon">
                      <MyRiDeleteBinLine />
                      <MyAiOutlinePlus />
                    </td>
                  </tr>
                  <tr>
                    <td>$130</td>
                    <td>MPU 175</td>
                    <td className="icon">
                      <MyRiDeleteBinLine />
                      <MyAiOutlinePlus />
                    </td>
                  </tr>
                  <tr>
                    <td>$130</td>
                    <td>MPU 175</td>
                    <td className="icon">
                      <MyRiDeleteBinLine />
                      <MyAiOutlinePlus />
                    </td>
                  </tr>
                </Table>
              </div>
            </Grid>
          </div>
          <FormProvider {...methods}>
            <Grid item xs={12} pt={4}>
              <StanderInput name="dec" Label="Description"></StanderInput>
            </Grid>
          </FormProvider>
          <Grid item xs={12} pt={4}>
            <AddButton
              fullWidth
              startIcon={<MyBiCheck />}
              sx={{ padding: "0.8rem" }}
            >
              Update Work Order
            </AddButton>
          </Grid>
        </Grid>
      </SlidingPane>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .container {
    min-width: 100%;
  }
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
`;
export default ChangeWorkorder;
