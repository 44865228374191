import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const Licenses = createApi({
  reducerPath: "licenses",
  baseQuery,
  tagTypes: ["licenses"],
  endpoints: (builder) => ({
    allLicenses: builder.query({
      query: (return_all) => ({
        url: "/installers/licenses",
        method: "GET",
        params: {
          return_all: return_all,
        },
      }),
      providesTags: ["licenses"],
    }),
    createLicense: builder.mutation<any, any>({
      query: (body) => ({
        url: "/installers/licenses/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["licenses"],
    }),
    deleteLicense: builder.mutation<any, any>({
      query: (id) => ({
        url: `/installers/licenses/${id}/delete`,
        method: "POST",
      }),
      invalidatesTags: ["licenses"],
    }),
    getStates: builder.query({
      query: () => ({
        url: `/settings/states`,
        method: "GET",
      }),
      // invalidatesTags: ["licenses"],
    }),
  }),
});

export const {
  useCreateLicenseMutation,
  useDeleteLicenseMutation,
  useAllLicensesQuery,
  useGetStatesQuery,
} = Licenses;
