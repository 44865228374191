import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Pagination,
} from "@mui/material";
import React, { useState } from "react";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import styled from "styled-components";
import Portflio from "./addProflioForm";
import { useGetPortfolioQuery } from "../../../store/RTK/profile/portfolio";
import MyModal from "../../common/Modal";
import DeletePortfolio from "./DeletePortfolio";
import { Edit } from "@mui/icons-material";
const Portfolio = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetPortfolioQuery(
    { page: page },
    {
      skip: !page,
    }
  );
  const allPortfolios = data?.data?.data;

  const getSingleData =
    currentId && allPortfolios?.find((item: any) => item.id === currentId);

  const [open, setOpen] = useState<boolean>(false);
  const handlChange = () => {
    setOpen(!open);
    setCurrentId(null);
  };

  const handleEdit = (id: string) => {
    setOpen(!open);
    setCurrentId(id);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <div>
      {" "}
      <Bigsection>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 5,
            }}
          >
            <p className="header">Portfolio</p>

            <AddButton
              startIcon={<MyAddIcon></MyAddIcon>}
              onClick={handlChange}
            >
              Add Project
            </AddButton>
          </Box>
        </Container>

        <Grid container columns={12} spacing={5}>
          {isLoading ? (
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                width: "100%",
                height: "200px",
                px: 15,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {allPortfolios?.map((item: any) => {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <div className="license">
                      <IconButton
                        onClick={() => handleEdit(item.id)}
                        className="edit"
                      >
                        <Edit />
                      </IconButton>
                      <div className="imgContainer">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="info">
                        <div className="content">
                          <p>
                            {item?.user?.name} • {item?.date}
                          </p>
                          <p>{item?.title}</p>0<p>{item.description}</p>
                          <Button
                            onClick={() => {
                              setOpenModal(true);
                              setCurrentId(item.id);
                            }}
                            variant="outlined"
                            color="inherit"
                          >
                            Delete{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
        <Box mt={5}>
          <Pagination
            count={Math.ceil(data?.data?.total / 10)}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Bigsection>
      {open && (
        <Portflio open={open} setOpen={setOpen} PortflioData={getSingleData} />
      )}
      {openModal && (
        <MyModal
          children={
            <DeletePortfolio
              handleClose={() => setOpenModal(false)}
              id={currentId}
            />
          }
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </div>
  );
};
const Bigsection = styled.div`
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #01545a;
  }
  .license {
    position: relative;
    background: #ffffff;
    .edit {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
      color: var(--primary-color);
    }
    .imgContainer {
      text-align: center;
      img {
        width: 100%;
      }
    }

    .info {
      .content {
        padding: 1rem 0.5rem;
        p {
          font-family: "Inter";
          font-style: normal;
          color: #667085;
          margin-bottom: 1rem;
          &:nth-child(1) {
            color: #01545a;
            font-weight: 600;
            font-size: 14px;
          }
          &:nth-child(2) {
            font-weight: 600;
            font-size: 20px;
            color: #101828;
          }
        }
        h4 {
        }
      }
      button {
        width: 100%;
        padding: 10px 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-bottom: 0.4rem;
        cursor: pointer;
      }
    }
  }
`;
export default Portfolio;
