import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";
export const WorkingDays = createApi({
  reducerPath: "workingDays",
  baseQuery,
  tagTypes: ["Working_Days"],
  endpoints: (builder) => ({
    allwokingDays: builder.query({
      query: (return_all) => ({
        url: "/working-days",
        method: "GET",
        params: {
          return_all: return_all,
        },
      }),
      providesTags: ["Working_Days"],
    }),
    postWorkingDays: builder.mutation({
      query: (data) => ({
        url: "/working-days/create",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Working_Days"],
    }),
  }),
});

export const { usePostWorkingDaysMutation, useAllwokingDaysQuery } =
  WorkingDays;
