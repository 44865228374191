import { Button, Grid } from "@mui/material";
import React, { ReactHTMLElement } from "react";
import styled from "styled-components";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import companyLogo from "../../../assets/Ellipse 1.png";
import AddCompanyForm from "./addCompanyForm";
import { useAllCompaniesQuery } from "../../../store/RTK/profile/company";

type Icompany = {
  date_established: string;
  id: number | string;
  slogan: string;
  address: string;
  name: string;
  license_class: string;
  license_type: string;
  data_established: string;
  state_license_link: string;
  workmanship_warranty: string;
  insurance_image: string;
  license_card_expire_date: string;
  logo: string;
};
const Company = () => {
  let return_all = "1";
  const { data, isLoading } = useAllCompaniesQuery(return_all);
  const companyInfo: Icompany = data?.data;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };
  const [open, setOpen] = React.useState<boolean>(false);
  const handlChange = () => {
    setOpen(!open);
  };
  console.log("data", data);
  return (
    <>
      <Bigsection>
        <Grid
          container
          columns={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <p className="header">Company</p>
          </Grid>
          <Grid item>
            <AddButton startIcon={<MyAddIcon />} onClick={handlChange}>
              Edit Company Profile
            </AddButton>
          </Grid>
        </Grid>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <div className="single-company">
              <div className="container">
                <div>
                  <img src={companyInfo?.logo} alt="" />
                  <p>{companyInfo?.name}</p>
                  <p>{companyInfo?.slogan}</p>
                </div>
                <div>
                  <p>
                    {" "}
                    address: <span>{companyInfo?.address}</span>
                  </p>
                  <p>
                    Data established:{" "}
                    <span>{companyInfo?.date_established}</span>
                  </p>
                  <p>
                    license type: <span> {companyInfo?.license_type}</span>
                  </p>
                  <p>
                    {" "}
                    License Class:
                    <span>{companyInfo?.license_class} </span>
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <div className="upload-images">
              <ul>
                <li>
                  <div>
                    <UploadButton variant="contained" onClick={handleClick}>
                      Picture
                    </UploadButton>{" "}
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                    ></input>
                    <span>License Card</span>{" "}
                  </div>
                </li>
                <li>
                  <div>
                    <span>License Card</span>{" "}
                  </div>
                </li>
              </ul>
            </div>
          </Grid> */}
        </Grid>
      </Bigsection>
      <AddCompanyForm open={open} setOpen={setOpen} />
    </>
  );
};

const Bigsection = styled.div`
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #01545a;
  }
  .single-company {
    margin: 1rem 0;
    width: 100%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.07));
    border-radius: 10px;
    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      div:nth-child(1) {
        flex-basis: 25%;
        text-align: center;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        padding: 20px 24px 81px;
        height: 201px;

        img {
          width: 53px;
          height: 54px;
        }
        p:nth-child(2) {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #474d56;
        }
        p:nth-child(3) {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #474d56;
        }
      }
      div:nth-child(2) {
        flex-basis: 75%;
        height: 201px;
        text-align: left;
        padding: 20px 24px 81px;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        p {
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #7a8494;
          padding: 0.15rem 0;
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #5c636f;
          }
        }
      }
      @media (max-width: 470px) {
        flex-direction: column;
        div:nth-child(1),
        div:nth-child(1) {
          flex-basis: 100%;
        }
      }
    }
  }
  .upload-images {
    ul {
      list-style: none;
      li {
        background: #f6fef9;
        border: 1px solid #6ce9a6;
        border-radius: 8px;
        padding: 16px;
        margin: 10px 0;
      }
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ecfdf3;
        mix-blend-mode: multiply;
        border-radius: 16px;
        padding: 4px 10px 4px 4px;
        width: 179px;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #027a48;
        }
      }
    }
  }
`;
export default Company;
