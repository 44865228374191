import React from "react";
import styled from "styled-components";
import { Box, CircularProgress, Grid } from "@mui/material";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import licenseImage from "../../../assets/licenseImage.png";
import SlidingPane from "../../common/slidingPane";
import AddLicenseForm from "./addLicenseForm";
import { useAllLicensesQuery } from "../../../store/RTK/profile/liecense";
import MyModal from "../../common/Modal";
import DeleteLiecese from "./deleteLicense";

type lienceTypes = {
  license_number: number;
  license_holder: string;
  license_type: string;
  address: string;
  expiration_date: string;
  lat: any;
  lng: any;
};
const Licenses = () => {
  let return_all = "1";
  const { data, isLoading } = useAllLicensesQuery(return_all);
  console.log("whatData", isLoading, data);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedLicense, setSelectedLicense] = React.useState<any>();
  const handleOpen = () => setOpenModal(true);
  const handlChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Bigsection>
        <Grid
          container
          columns={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Grid item>
            <p className="header">License</p>
          </Grid>
          <Grid item>
            <AddButton
              startIcon={<MyAddIcon></MyAddIcon>}
              onClick={handlChange}
            >
              Add License
            </AddButton>
          </Grid>{" "}
        </Grid>
        {isLoading ? (
          <>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                width: "100%",
                height: "200px",
                px: 15,
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {" "}
            {data.data.length > 0 ? (
              <>
                {" "}
                <Grid container columns={12} spacing={5}>
                  {data.data.map((e: lienceTypes) => {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <div className="license">
                          <div className="imgContainer">
                            <img src={licenseImage} alt="" />
                          </div>
                          <div className="info">
                            <div className="content">
                              <p>
                                License Number: <span>{e?.license_number}</span>
                              </p>
                              <p>
                                License State: <span>{e.address}</span>
                              </p>
                              <p>
                                License Type: <span>{e.license_type}</span>
                              </p>
                              <p>
                                License Expiration Date:{" "}
                                <span>{e.expiration_date}</span>
                              </p>
                              <button
                                onClick={() => {
                                  setSelectedLicense(e);
                                  handleOpen();
                                }}
                              >
                                Delete{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            ) : (
              <h3 style={{ textAlign: "center" }}>No License</h3>
            )}
          </>
        )}
      </Bigsection>
      <AddLicenseForm open={open} setOpen={setOpen}></AddLicenseForm>
      <DeleteLiecese
        open={openModal}
        setOpen={setOpenModal}
        selectedLicense={selectedLicense}
      ></DeleteLiecese>
    </>
  );
};
const Bigsection = styled.div`
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #01545a;
  }
  .license {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    .imgContainer {
      background: #b0cacc;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      text-align: center;
      img {
        width: 70%;
      }
    }

    .info {
      .content {
        padding: 1rem 0.5rem;
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #667085;
          margin-bottom: 1rem;
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
          }
        }
      }
      button {
        width: 100%;
        padding: 10px 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-bottom: 0.4rem;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 470px) {
  }
`;

export default Licenses;
