import React from "react";
import {
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import {

  useGetSingleJobOrderQuery,
} from "../../../../store/RTK/jobOrder";
import { useParams } from "react-router-dom";
const TimeFrame = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetSingleJobOrderQuery(id);

  return(
    <>
    {isLoading ? (
         <Box
         sx={{
           display: "grid",
           placeItems: "center",
           width: "100%",
           height: "200px",
           px: 15,
         }}
       >
         <CircularProgress />
       </Box>

    ):(
      <p>time frame : {data.data.time_frame} </p>
    )}
    </>
  )
};

export default TimeFrame;
