import React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  MyBsPerson,
  MyCalendarTodayOutlinedIcon,
  MyChevronRightIcon,
  MyFiBriefcase,
  MyFiClipboard,
  MyIoDocumentTextOutline,
  MyMenuIcon,
} from "../common/icons";
import IconButton from "@mui/material/IconButton";
import { NavBarStyle } from "../../style/NabVarStyle";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GiExitDoor } from "react-icons/gi";
import { ExitToApp } from "@mui/icons-material";
import { logOut } from "../../store/reducers/auth/authSlice";
import { useProfileQuery } from "../../store/RTK/profile/profile";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Tlist = {
  id: number;
  name: string;
  icon: any;
  path: string;
}[];

const AllRouts: Tlist = [
  {
    id: 1,
    name: "Working days",
    icon: <MyCalendarTodayOutlinedIcon />,
    path: "calendar-events",
  },
  { id: 2, name: "Adder Rate", icon: <MyFiBriefcase />, path: "adders" },
  { id: 3, name: "Job order", icon: <MyFiClipboard />, path: "jobs-orders" },
  {
    id: 4,
    name: "Reporting",
    icon: <MyIoDocumentTextOutline />,
    path: "reporting",
  },
  {
    id: 5,
    name: "Profile",
    icon: <MyBsPerson />,
    path: "profile",
  },
];
/* add all list  */

const NavBar = () => {
  const dispatch = useDispatch();
  const { data } = useProfileQuery({});

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <NavBarStyle>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MyMenuIcon />
        </IconButton>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className={open ? "header-open" : "header-close"}>
            {open ? (
              <>
                <img src="https://www.technician.boxbyld.tech/static/media/boxByld.1b915680c09d609cf02d3ec04c9df993.svg"></img>
              </>
            ) : (
              <></>
            )}
            <IconButton onClick={handleDrawerClose}>
              {open ? (
                <>
                  <MyChevronRightIcon />{" "}
                </>
              ) : (
                <>
                  <MyMenuIcon></MyMenuIcon>
                </>
              )}
            </IconButton>
          </DrawerHeader>
          <div className="nav">
            <div>
              <List>
                {AllRouts.map((item) => (
                  <NavLink to={item.path}>
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>

                        <ListItemText
                          primary={item.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </div>
            <div>
              {" "}
              <List>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="profile-img"
                        src={data?.data?.image}
                        alt=""
                      />
                    </ListItemIcon>{" "}
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary={data?.data?.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => dispatch(logOut())}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="profile"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ExitToApp />
                    </ListItemIcon>{" "}
                    <div style={{ display: !open ? "none" : "block" }}>
                      <ListItemText
                        primary="loge Out"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </div>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#FAFCFE",
            minHeight: "100vh",
          }}
          // style={{ overflowX: "hidden" }}
        >
          {/* <DrawerHeader /> */}
          <Outlet />
        </Box>
      </Box>
    </NavBarStyle>
  );
};

export default NavBar;
