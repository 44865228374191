import React from "react";
import styled from "styled-components";

type props = {
  errors: Array<{ field: string; msg: string }>;
};
const Errors: React.FC<props> = ({ errors }: props) => {
  return (
    <Bigsetction>
      {" "}
      {errors?.length > 0 ? (
        <>
          <p>Errors</p>
          <ul>
            {errors?.map((err: any, i) => {
              return (
                <li key={i}>
                  {err?.field.replace("_", " ")} :{err.msg}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <></>
      )}
    </Bigsetction>
  );
};
const Bigsetction = styled.div`
  p {
    text-align: center;
  }
  ul {
    list-style: none;
    li {
      color: red;
    }
  }
`;
export default Errors;
