import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import SlidingPane from "../../../common/slidingPane";
import { Checkbox, FormControlLabel, Grid , MenuItem  , FormGroup} from "@mui/material";
import {
  MyInputLable,
  MyTextFiled,
  StadardSelect,

} from "../../../common/standardSelect";
import StanderInput from "../../../common/standerInput";
import { AddButton } from "../../../common/Buttons/AddButton";
import { MyBiCheck } from "../../../common/icons";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Tlogin } from "../../../../typs/loginType";
// import {useSendTeamInvitationMutation} from "../../../../store/RTK/profile/team";
type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTeamForm = ({ open, setOpen }: props) => {
  const [errors, setError] = React.useState<any>("");
  const [is_belong_to_company_value, setis_belong_to_company_value] = React.useState<any>(0);
  const methods = useForm<Tlogin>({ mode: "onChange" });
  const dispatch = useDispatch();
  // const [sendTeamInvitation, { isLoading, isError, isSuccess }] =
  // useSendTeamInvitationMutation();


  const handleChangeChecks = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event" , event)
    event.target.checked == true ?  setis_belong_to_company_value(1) :setis_belong_to_company_value(0)
  }
  // const onSubmit = async (data: any) => {
   
  //   try {
  //     await sendTeamInvitation({ ...data , is_has_share:0  , is_belong_to_company : is_belong_to_company_value}).unwrap();
  //     if (!isError && !isLoading && isSuccess) {
  //       toast.success("License has been created");
  //     }else{
        
  //     }
  //   } catch (error) {
     
  //     setError(error);
  //   }
  // };

  return (
    <Bigsection>
       <FormProvider {...methods}>
      <SlidingPane open={open} setOpen={setOpen}>
      {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} className="line">
            <h3>Add team member</h3>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Invite User Type</MyInputLable>

            <StanderInput
                  required
                  Label="user type"
                  name="user_type"
                 
                  isSelect={true}
                >
            <MenuItem  value="installer">
            installer
                              </MenuItem>
                              <MenuItem  value="sales_rep">
                              sales rep
                              </MenuItem>
            </StanderInput>
          </Grid>
          <Grid item xs={12}>
          

<FormGroup onChange={handleChangeChecks}>
<FormControlLabel
              control={<Checkbox  name="is_belong_to_company"  sx={{ fonttSize: "10px" }} />}
              label="Does this User belong to your Company "
            />
                    </FormGroup>
          </Grid>
          <Grid item xs={12}>
               <StanderInput
               type="number"
                  required
                  Label="contract percentage"
                  name="contract_percentage"
                 
                />{" "}
          </Grid>
          <Grid item xs={12}>
             <StanderInput
                  required
                  Label="full name"
                  name="full_name"
                 
                />{" "}
          </Grid>
          <Grid item xs={12}>
               <StanderInput
                  required
                  Label="email"
                  name="email"
                />{" "}
          </Grid>
          <Grid item xs={12}>
          <StanderInput
                  required
                  Label="phone"
                  name="phone"
                />{" "}
          </Grid>
          <Grid item xs={12}>
            <AddButton  type="submit" fullWidth startIcon={<MyBiCheck />}>
              Invite
            </AddButton>
          </Grid>
        </Grid>
        {/* </form> */}
      </SlidingPane>
      </FormProvider>
    </Bigsection>
  );
};

const Bigsection = styled.div`
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    color: #667085;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  background: #ffffff !important;
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #00818a;
  }
  .css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: #101828;
  }
  button {
    svg {
      color: white;
    }
  }
`;
export default AddTeamForm;
