import React, { useMemo, useState } from "react";
import styled from "styled-components";
import AddAddersForm from "../addAddersForm";
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  TextField,
  debounce,
} from "@mui/material";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon, MyCiEdit, MyRiDeleteBinLine } from "../../common/icons";
import { Table } from "../../../style/TableStyle";
import DeleteAdder from "./deleteAdder";
import EditAdder from "./editAdder";
import { Link } from "react-router-dom";
import { useGetAddarsQuery } from "../../../store/RTK/adders";
import { Search } from "@mui/icons-material";

type adder = {
  id: number;
  name: number;
  price: string;
  per: string;
  description: string;
};
const AddersRate = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>(undefined);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [selectedAdder, setSelectedAdder] = React.useState<any>();

  const { data, isFetching, isLoading } = useGetAddarsQuery({
    page,
    search,
  });

  const addersList = data?.data?.data;

  const debouncedSearchTerm = useMemo(() => debounce(setSearch, 500), []);
  const handleSearch = (event: React.ChangeEvent<any>) => {
    debouncedSearchTerm(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const adderList = () => {
    return addersList?.map((item: any) => (
      <tr key={item.id}>
        <td>{item?.add_category?.id}</td>
        <td>{item?.add_category?.name}</td>
        <td>{item?.operator}</td>
        <td>{item?.condition_number}</td>
        <td>{item?.condition_on}</td>
        <td>${item?.price}</td>
        <td>{item?.per}</td>
        <td>{item?.price_type}</td>
        <td className="actions">
          <button
            onClick={async () => {
              await setSelectedAdder(item);
              await setOpenModal(true);
            }}
          >
            <MyRiDeleteBinLine />
          </button>
          <button
            onClick={async () => {
              await setSelectedAdder(item);
              await setOpenEditModal(true);
            }}
          >
            <MyCiEdit />
          </button>
        </td>
      </tr>
    ));
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const handlChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <div className="container">
              <div className="firstdiv">
                <div>
                  <Box width={405}>
                    <TextFieldStyle
                      onChange={handleSearch}
                      fullWidth
                      size="small"
                      placeholder="Search Id , Address , Beneficiary"
                      InputProps={{
                        startAdornment: (
                          <Search sx={{ color: "var(--basicGray)" }} />
                        ),
                      }}
                    />
                  </Box>
                </div>
                <div>
                  {" "}
                  <Link to="/home/adders/create-adders">
                    <AddButton startIcon={<MyAddIcon />}>
                      Create Adders rate
                    </AddButton>
                  </Link>
                </div>
              </div>
              <Grid item xs={12}>
                <div className="seconddiv">
                  {isLoading || isFetching ? (
                    <Box
                      sx={{
                        display: "grid",
                        placeItems: "center",
                        width: "100%",
                        height: "200px",
                        px: 15,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <MyTable>
                      <tr>
                        <th>Cetegory</th>
                        <th>Adder name</th>
                        <th>operator</th>
                        <th>condition number</th>
                        <th>condition on</th>
                        <th>Price</th>
                        <th>Per</th>
                        <th>price type</th>
                        <th>Actions</th>
                      </tr>
                      {adderList()}

                      <>
                        <Stack
                          spacing={2}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            my: 5,

                            // justifyContent: "flex-end",
                          }}
                        >
                          <Pagination
                            count={Math.ceil(data?.data?.total / 15)}
                            page={page}
                            onChange={handlePageChange}
                          />
                        </Stack>
                      </>
                    </MyTable>
                  )}
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Bigsection>

      {open && <AddAddersForm open={open} setOpen={setOpen} />}

      {openModal ? (
        <DeleteAdder
          open={openModal}
          setOpen={setOpenModal}
          selectedAdder={selectedAdder}
        />
      ) : null}

      {openEditModal ? (
        <EditAdder
          open={openEditModal}
          setOpen={setOpenEditModal}
          selectedAdder={selectedAdder}
        />
      ) : null}
    </>
  );
};

export default AddersRate;
export const Bigsection = styled.div`
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;
const MyTable = styled(Table)`
  && {
  }
  th {
    &:nth-child(2) {
      text-align: left;
    }
  }
  td {
    &:nth-child(2) {
      text-align: left;
    }
  }
  .actions {
    display: flex;
    gap: 0.25rem;
    svg {
      transition: 0.5s all;

      &:hover {
        transform: scale(1.3, 1.3);
      }
    }
  }
`;

const TextFieldStyle = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 0.5rem;
      width: 100%;
      background: #efefef;
      padding-inline: 1rem;
      fieldset {
        border: none;
      }
      ::placeholder {
        font-size: 10px;
      }
      .MuiOutlinedInput-input {
        /* padding: 13px 1rem; */
        ::placeholder {
          font-size: 15px;
          height: 1.59em;
          padding-inline-start: 0.5rem;
        }
      }
    }
  }
`;
