import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";

interface MyCardProps {
  data: {
    apr: null | string;
    created_at: string;
    dealer_fees: null | string;
    deleted_at: null | string;
    finance_company_type: null | string;
    finance_type: null | string;
    id: number;
    product_name: string;
    term: null | string;
    type: string;
    updated_at: string;
    user_id: number;
  };
}

const MyCard: React.FC<MyCardProps> = ({ data }) => {
  const {
    apr,

    dealer_fees,
    id,
    product_name,
    term,
    type,

    finance_company_type,
    finance_type,
  } = data;

  return (
    <Card sx={{ minHeight: 250 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {type}
        </Typography>
        <Box>
          <Typography color="textSecondary">ID: {id}</Typography>
          {type === "finance" ? (
            <>
              <Typography color="textSecondary">
                finance company type :{" "}
                {finance_company_type === null ? "N/A" : finance_company_type}
              </Typography>
              <Typography color="textSecondary">
                Dealer Fees: {dealer_fees === null ? "N/A" : dealer_fees}
              </Typography>
              <Typography color="textSecondary">
                APR: {apr === null ? "N/A" : apr}
              </Typography>
              <Typography color="textSecondary">
                Term: {term === null ? "N/A" : term}
              </Typography>
              <Typography color="textSecondary">
                finance_type : {finance_type}
              </Typography>
            </>
          ) : (
            <Typography color="textSecondary">
              product name: {product_name}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MyCard;
