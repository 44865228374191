import React, { useEffect } from "react";
import MyModal from "../../../common/Modal";
import styled from "styled-components";
import { DeleteButton, NoButton } from "../../../common/Buttons/deleteButton";
import { useDeleteSingleAdderMutation } from "../../../../store/RTK/adders";
import { toast } from "react-toastify";
type props = {
  open?: any;
  setOpen?: any;
  selectedAdder: {
    id: number;
    name: number;
    price: string;
    per: string;
    description: string;
    expiration_date: string;
  };
};
const DeleteAdder: React.FC<props> = ({
  open,
  setOpen,
  selectedAdder,
}: props) => {
  const [deleteNewAdder, { isLoading, isSuccess, isError }] =
    useDeleteSingleAdderMutation();

  const Delete = async () => {
    await deleteNewAdder({ id: selectedAdder.id });
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleSubmit = async () => {
      if (isSuccess) {
        await toast.success("Deleted Successfully");
        await setOpen(false);
      }
      if (isError) {
        await toast.error("something went wrong");
        await setOpen(false);
      }
    };

    handleSubmit();
  }, [isSuccess, isError]);

  return (
    <Bigsection>
      <MyModal open={open} setOpen={setOpen}>
        <h5>
          Are You sure you want to delete this Adder {selectedAdder?.name}
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "1rem 0",
          }}
        >
          <DeleteButton onClick={Delete}>
            {isLoading ? <>Deleting</> : <>Yes, Delete</>}
          </DeleteButton>{" "}
          <NoButton onClick={handleClose}>No</NoButton>
        </div>
      </MyModal>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .buttons {
    display: flex;
  }
`;
export default DeleteAdder;
