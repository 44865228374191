import { Box, Button } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";

import { CrisisAlert } from "@mui/icons-material";
import { useDeletePortfolioMutation } from "../../../../store/RTK/profile/portfolio";

const DeletePortfolio = ({
  handleClose,
  id,
}: {
  handleClose: any;
  id: string | null;
}) => {
  const [deletePortfolio, { isLoading }] = useDeletePortfolioMutation({});

  const handelDeletPortfolio = async () => {
    await deletePortfolio(id)
      .unwrap()
      .then(async (res) => {
        toast.success("The Portfolio Deleted  sucessfully");
        handleClose();
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };
  return (
    <DeleteContainer>
      <Box>
        <CrisisAlert sx={{ fontSize: "3rem" }} color="error" />
      </Box>
      <h2>Are you shore you want to Delete this Portfolio</h2>
      <FlexWithSpaceBetween sx={{ px: 5 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handelDeletPortfolio()}
        >
          {isLoading ? "loading...." : " Delete"}
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </FlexWithSpaceBetween>
    </DeleteContainer>
  );
};

const DeleteContainer = styled(Box)`
  && {
    text-align: center;
    h2 {
      font-size: calc(0.8rem + 1vw);
      font-weight: bold;
      color: var(--darkGray);
      margin-bottom: 2rem;
    }
  }
`;

const FlexWithSpaceBetween = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export default DeletePortfolio;
