import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/Inbox";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { FiBriefcase } from "react-icons/fi";
import { FiClipboard } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFilter } from "react-icons/bs";
import { BsPerson } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import MapIcon from "@mui/icons-material/Map";

export const MyMailIcon = () => {
  return <MailIcon></MailIcon>;
};
export const MyInboxIcon = () => {
  return <InboxIcon></InboxIcon>;
};
export const MyIconButton = () => {
  return <IconButton></IconButton>;
};

export const MyMenuIcon = () => {
  return <MenuIcon></MenuIcon>;
};
export const MyChevronLeftIcon = () => {
  return <ChevronLeftIcon></ChevronLeftIcon>;
};
export const MyChevronRightIcon = () => {
  return <ChevronRightIcon></ChevronRightIcon>;
};
export const MyMoveToInboxIcon = () => {
  return <MoveToInboxIcon></MoveToInboxIcon>;
};
export const MyAddIcon = () => {
  return <AddIcon></AddIcon>;
};
export const MyExpandMoreIcon = () => {
  return <ExpandMoreIcon></ExpandMoreIcon>;
};
export const MyCloseIcon = () => {
  return <CloseIcon></CloseIcon>;
};
export const MyCalendarTodayOutlinedIcon = () => {
  return <CalendarTodayOutlinedIcon></CalendarTodayOutlinedIcon>;
};
export const MyFiBriefcase = () => {
  return <FiBriefcase></FiBriefcase>;
};
export const MyFiClipboard = () => {
  return <FiClipboard></FiClipboard>;
};
export const MyRiDeleteBinLine = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.5 4.99935H4.16667M4.16667 4.99935H17.5M4.16667 4.99935V16.666C4.16667 17.108 4.34226 17.532 4.65482 17.8445C4.96738 18.1571 5.39131 18.3327 5.83333 18.3327H14.1667C14.6087 18.3327 15.0326 18.1571 15.3452 17.8445C15.6577 17.532 15.8333 17.108 15.8333 16.666V4.99935H4.16667ZM6.66667 4.99935V3.33268C6.66667 2.89065 6.84226 2.46673 7.15482 2.15417C7.46738 1.84161 7.89131 1.66602 8.33333 1.66602H11.6667C12.1087 1.66602 12.5326 1.84161 12.8452 2.15417C13.1577 2.46673 13.3333 2.89065 13.3333 3.33268V4.99935M8.33333 9.16602V14.166M11.6667 9.16602V14.166"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const MyAiOutlinePlus = () => {
  return <AiOutlinePlus></AiOutlinePlus>;
};
export const MyBiCheck = () => {
  return <BiCheck></BiCheck>;
};
export const MyFiPhone = () => {
  return <FiPhone></FiPhone>;
};
export const MyMapIcon = () => {
  return <MapIcon></MapIcon>;
};
export const MyCiMail = () => {
  return <CiMail></CiMail>;
};
export const MyIoDocumentTextOutline = () => {
  return <IoDocumentTextOutline></IoDocumentTextOutline>;
};
export const MyBsFilter = () => {
  return <BsFilter></BsFilter>;
};
export const MyBsPerson = () => {
  return <BsPerson></BsPerson>;
};
export const MyCiEdit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_1074_101093)">
        <path
          d="M14.167 2.5009C14.3859 2.28203 14.6457 2.10842 14.9317 1.98996C15.2176 1.87151 15.5241 1.81055 15.8337 1.81055C16.1432 1.81055 16.4497 1.87151 16.7357 1.98996C17.0216 2.10842 17.2815 2.28203 17.5003 2.5009C17.7192 2.71977 17.8928 2.97961 18.0113 3.26558C18.1297 3.55154 18.1907 3.85804 18.1907 4.16757C18.1907 4.4771 18.1297 4.7836 18.0113 5.06956C17.8928 5.35553 17.7192 5.61537 17.5003 5.83424L6.25033 17.0842L1.66699 18.3342L2.91699 13.7509L14.167 2.5009Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1074_101093">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
