import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const portfolioApiSLice = createApi({
  reducerPath: "portfolio",
  baseQuery: baseQuery,
  tagTypes: ["portfolio"],
  endpoints: (builder) => ({
    // get portfolio data
    getPortfolio: builder.query<any, any>({
      query: ({ page }) => ({
        url: "portfolio",

        params: {
          page: page,
        },
      }),
      providesTags: ["portfolio"],
    }),

    // add portfolio data
    addPortfolio: builder.mutation<any, any>({
      query: (data) => ({
        url: "/portfolio/create",
        method: "post",
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(portfolioApiSLice.util.invalidateTags(["portfolio"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),

    // edit portfolio data
    editPortfolio: builder.mutation<any, any>({
      query: (data) => ({
        url: `/portfolio/${data.id}/edit`,
        method: "post",
        body: data.data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(portfolioApiSLice.util.invalidateTags(["portfolio"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),

    deletePortfolio: builder.mutation<any, any>({
      query: (id) => ({
        url: `/portfolio/${id}/delete`,
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(portfolioApiSLice.util.invalidateTags(["portfolio"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useGetPortfolioQuery,
  useAddPortfolioMutation,
  useDeletePortfolioMutation,
  useEditPortfolioMutation,
} = portfolioApiSLice;
