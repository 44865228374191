import { Box, Grid } from "@mui/material";
import StanderInput from "../common/standerInput";
import SelectInput from "../common/SelectInput/SelectInput";
import { useCategoriesQuery } from "../../store/RTK/auth";

const StepOne = () => {
  const { data } = useCategoriesQuery({});

  const listOfAllCategory = data?.data?.map((item: any) => ({
    id: item.type,
    name: item.name,
  }));

  return (
    <Box>
      <Grid container columns={12}>
        <Grid item md={6} mb={1.5} sx={{ pr: { md: 1 } }}>
          <StanderInput name="name" Label="Full Name" />
        </Grid>
        <Grid item md={6} mb={1.5}>
          <StanderInput name="email" Label="Email" />
        </Grid>
        <Grid xs={12} mb={1.5}>
          <StanderInput name="password" Label="Password " type="password" />
        </Grid>

        <Grid xs={12} mb={1.5}>
          <StanderInput
            name="password_confirmation"
            Label="Password Confirmation"
            type="password"
          />
        </Grid>
        <Grid xs={12} mb={1.5}>
          <StanderInput name="phone" Label="Phone Number" />
        </Grid>
        <Grid xs={12} mb={1.5}>
          <SelectInput
            name="category"
            label="category "
            data={listOfAllCategory}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StepOne;
