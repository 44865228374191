import React, { useState } from "react";
import styled from "styled-components";
import { Box, CircularProgress, Grid, Pagination, Stack } from "@mui/material";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon, MyBsFilter } from "../../common/icons";
import { Table } from "../../../style/TableStyle";
import { FilterButton } from "../../common/Buttons/FilterButton";
import SlidingPane from "../../common/slidingPane";
import Filter from "./filter";
import { useGetJobOrderQuery } from "../../../store/RTK/jobOrder";

const Collections = () => {
  const [collection, setCollection] = useState("not_eligible");
  const [page, setPage] = useState<number>(1);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const { data, isFetching, isLoading } = useGetJobOrderQuery({
    collection_status: collection,
    page,
  });
  const workingJobList = data?.data?.data;

  const [open, setOpen] = React.useState<boolean>(false);
  const handlChange = () => {
    setOpen(!open);
  };

  const workingList = () => {
    return workingJobList?.map((item: any) => (
      <tr key={item.id}>
        <td>{item?.customer_name}</td>
        <td>{item?.customer_address}</td>
        <td>
          <div
            className={
              item?.status === "completed"
                ? "success"
                : item?.status === "new"
                ? "new"
                : item?.status === "in_progress"
                ? "pending"
                : "pending "
            }
          >
            <p>
              <span className="dot"></span> {item?.status}
            </p>
          </div>
        </td>
        <td>${item?.expected_earnings}</td>
        <td>{item?.time_frame}</td>
      </tr>
    ));
  };

  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <div className="container">
            <div className="firstdiv">
              <div>
                <h4>Collection</h4>
              </div>
              <div>
                <FilterButton startIcon={<MyBsFilter />} onClick={handlChange}>
                  Filter
                </FilterButton>
              </div>
            </div>
            <Grid item xs={12}>
              <div className="seconddiv">
                {isLoading || isFetching ? (
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      width: "100%",
                      height: "200px",
                      px: 15,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table>
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Earning</th>
                      <th>Date</th>
                    </tr>
                    {workingList()}
                  </Table>
                )}
              </div>
            </Grid>
          </div>

          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: 5,

              justifyContent: "flex-end",
            }}
          >
            <Pagination
              count={Math.ceil(data?.data?.total / 10)}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </Grid>
      </Bigsection>
      {open && (
        <Filter
          collection={collection}
          setCollection={setCollection}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};
const Bigsection = styled.div`
  .container {
    min-width: 100%;
  }
  .success,
  .pending {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e7d59;
    .dot {
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #28a676;
      margin-right: 8px;
    }
    p {
      background: #eaf6f1;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      font-size: 12px;
      line-height: 18px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
    }
  }
  .pending {
    color: #f79009;
    .dot {
      background-color: #f79009;
    }
    p {
      background: #fffaeb;
    }
  }
  td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #667085;
  }
`;
export default Collections;
