import { type } from "os";
import React, { useEffect, useState } from "react";
import SlidingPane from "../../common/slidingPane";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Tlogin } from "../../../typs/loginType";
import StanderInput from "../../common/standerInput";
import Grid from "@mui/material/Grid";
import { SubmitButton } from "../../common/Buttons/SubmitButton";
import styled from "styled-components";
import {
  MyInputLable,
  MyTextFiled,
  StadardSelect,
} from "../../common/standardSelect";
import {
  useGetAllCategoriesChildQuery,
  useGetAllCategoriesQuery,
} from "../../../store/RTK/adders";
import {
  useCreateAdderMutation,
  useGetAddarsQuery,
  useGetRequiredAddersQuery,
  useGetRequiredNotQuery,
} from "../../../store/RTK/requiredAdders";
import SelectInput from "../../common/SelectInput/SelectInput";
import {
  CircularProgress,
  FormHelperText,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { MyIconButton } from "../../common/icons";
import { RootState } from "../../../store/store";

const allPrice = [
  { id: "material", name: "material" },
  { id: "labor", name: "labor" },
  { id: "both", name: "both" },
];

const allPer = [
  { id: "watt", name: "watt" },
  { id: "unit", name: "unit" },
  { id: "ft", name: "feet" },
  { id: "sq", name: "square" },
];

const validationSchema = yup.object().shape({
  adders: yup.array().of(
    yup.object().shape({
      price: yup
        .number()
        .typeError("The Price Must be Number")
        .required("Price is required"),
      price_type: yup.string().required("Price Type is required"),
      per: yup.string().required("Per is required"),
      description: yup.string().required("Description is required"),
    })
  ),
});

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddAddersForm: React.FC<props> = ({ open, setOpen }) => {
  const [craeteAdder, { isLoading, isError, isSuccess }] =
    useCreateAdderMutation();
  let status = "pending";
  let return_all = "1";
  let page = 1;

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  methods.watch(["category"]);
  // Get All Adders

  //get all category
  const { data: allCategories } = useGetAllCategoriesQuery({});

  const allCategoriesList = allCategories?.data;

  const [parentId, setParentId] = useState<any>(null);

  const handleOnChange = (id: any) => {
    setParentId(id);
  };

  const {
    data,
    isLoading: childeLoading,
    isFetching,
  } = useGetAllCategoriesChildQuery(
    { parent_id: parentId },
    {
      skip: !parentId,
    }
  );

  useEffect(() => {
    if (isError) toast.error("something Went Wrong");

    if (isSuccess) toast.success("The Adders Saved Successfully ");
  }, [isError, isSuccess]);

  const onSubmit: SubmitHandler<Tlogin> = (data) => {
    craeteAdder(data);
    console.log("data", data);
  };

  return (
    <SlidingPane open={open} setOpen={setOpen} bigger>
      <BigSection>
        <div className="container">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} spacing={1}>
                <Grid item xs={12} mb={3}>
                  <h4>Create Adders rate</h4>
                  <p className="upload">
                    Upload and attach files to this project.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={methods.control}
                    name="category"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className={"mb-10"}>
                        <MyInputLable htmlFor="category" required>
                          Select Category{" "}
                        </MyInputLable>
                        <div className="mt-3">
                          <StadardSelect
                            fullWidth
                            size="small"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleOnChange(e.target.value);
                            }}
                            id="category"
                          >
                            {allCategoriesList?.map((item: any) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </StadardSelect>

                          <ErrorMessage
                            render={({ message }) => (
                              <FormHelperText sx={{ color: "#f00" }}>
                                {message}
                              </FormHelperText>
                            )}
                            errors={methods.formState.errors}
                            name="category"
                          />
                        </div>
                      </div>
                    )}
                  />
                </Grid>
                {isFetching || childeLoading ? (
                  <>
                    <div className="loader">
                      <CircularProgress size={50} thickness={5} />
                    </div>{" "}
                  </>
                ) : (
                  <>
                    {data?.data?.map((item: any, index: any) => (
                      <>
                        <Grid item xs={12} md={6} lg={3}>
                          <StanderInput
                            name={`adders[${index}].price`}
                            Label={item.name}
                            placeholder="$"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <SelectInput
                            data={allPrice}
                            label="Price Type"
                            name={`adders[${index}].price_type`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <SelectInput
                            data={allPer}
                            label="per"
                            name={`adders[${index}].per`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <StanderInput
                            name={`adders[${index}].description`}
                            Label={"Description"}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} display={"none"}>
                          <StanderInput
                            name={`adders[${index}].adder_category_id`}
                            defaultValue={item?.id}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} display={"none"}>
                          <StanderInput
                            name={`adders[${index}].parent_category_id`}
                            defaultValue={methods.getValues("category")}
                          />
                        </Grid>
                        <Grid item xs={12} className="hr">
                          <hr />
                        </Grid>
                      </>
                    ))}

                    <Grid item xs={12}>
                      <SubmitButton type="submit" fullWidth>
                        {isLoading ? " Loading...." : "Add"}
                      </SubmitButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </FormProvider>
        </div>
      </BigSection>
    </SlidingPane>
  );
};
const BigSection = styled.div`
  overflow-x: hidden;
  width: 100%;
  .upload {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .container {
    width: 100%;
  }
`;
export default React.memo(AddAddersForm);
