import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ListAdderCard = ({ categoryList }: any) => {
  return (
    <Bigection>
      <div className="content">
        {categoryList?.map((item: any) => (
          <div key={item.id} className="singleDiv">
            <Link to={`?children="no-children"`}>
              <img
                src={item?.image ? item?.image : "/Assets/solar-house.png"}
                width={"25px"}
                height={"25px"}
                alt=""
              />
              <h3>{item?.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </Bigection>
  );
};

const Bigection = styled.section`
  min-height: 50vh;

  display: grid;
  place-items: center;
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    width: 90%;
    margin: 2rem auto;
    gap: 2rem;
    margin-top: 2rem;
  }
  .singleDiv {
    display: flex;
    min-height: 13rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding: 2.5rem;
    border-radius: 0.5rem;
    background: rgb(230, 242, 243);
    box-shadow: rgba(0, 52, 60, 0.23) 0px 8px 15px 0px;
    transition: all 0.3s ease 0s;
    color: rgb(0, 97, 104);
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center center;
    }
    h3 {
      text-transform: uppercase;
      font-size: medium;
    }
    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export default ListAdderCard;
