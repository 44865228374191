import React, { useEffect } from "react";
import styled from "styled-components";
import SlidingPane from "../../../common/slidingPane";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
} from "@mui/material";

import { AddButton } from "../../../common/Buttons/AddButton";

import SelectInput from "../../../common/SelectInput/SelectInput";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import RadioButton from "../../../common/RadioButton";
import { comapnyTyps } from "../../../../constant/List";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlPoint, Delete } from "@mui/icons-material";
import StanderInput from "../../../common/standerInput";
import { useCreateServiceMutation } from "../../../../store/RTK/profile/services";
import { toast } from "react-toastify";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

let options = [
  { name: "finance", value: "finance", id: "finance" },
  { name: "distributor", value: "distributor", id: "distributor" },
];

const schema = yup.object().shape({
  type: yup.string().required(),
  finance_company_type: yup.string().when("type", {
    is: "finance",
    then: (schema) => schema.required(),
  }),
  product_name: yup.string().when("type", {
    is: "distributor",
    then: (schema) => schema.required("product Name is required"),
  }),
  finance: yup.array().of(
    yup.object().shape({
      finance_type: yup.string().required("type is required"),
      apr: yup.string().required("apr is required"),
      term: yup.string().required("term is required"),
      dealer_fees: yup.string().required("dealer fees is required"),
    })
  ),
});

const AddServiceForm = ({ open, setOpen }: props) => {
  const [create, { isLoading }] = useCreateServiceMutation();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { handleSubmit, control, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "finance",
  });

  const onSubmit = async (data: any) => {
    const formData = new FormData();

    // for (let key in data) {
    //   if (key === "finance") {
    //     for (let i = 0; i < data.finance.length; i++) {
    //       for (let j in data.finance[i]) {
    //         formData.append(`finance[${i}][${j}]`, data.finance[i][j]);
    //       }
    //     }
    //   } else {
    //     formData.append(key, data[key]);
    //   }
    // }

    let mainData = {};

    for (let key in data) {
      const currentKey = data[key];
      if (Array.isArray(currentKey)) {
        currentKey?.map((item, index) => {
          for (let value in item) {
            formData.append(`finance[${index}][${value}]`, item[value]);
            mainData = {
              ...mainData,
              [`finance[${index}][${value}]`]: item[value],
            };
          }
        });
      } else {
        formData.append(key, data[key]);
        mainData = {
          ...mainData,
          [key]: data[key],
        };
      }
    }

    create(data)
      .unwrap()
      .then((res) => {
        toast.success("Service added successfully");
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };

  return (
    <div>
      <BigSection>
        {" "}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SlidingPane big open={open} setOpen={setOpen}>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={12} className="line">
                  <h3>Add Service</h3>
                </Grid>

                <Grid item xs={12}>
                  <SelectInput data={options} label="Choose Type" name="type" />
                </Grid>

                {watch("type") === "distributor" ? (
                  <Grid item xs={12}>
                    <StanderInput
                      Label="product name"
                      name="product_name"
                      placeholder="Prodact Name"
                    />
                  </Grid>
                ) : null}

                {watch("type") === "finance" ? (
                  <>
                    {" "}
                    <Grid item xs={12}>
                      <RadioButton
                        name="finance_company_type"
                        Label="Choose company "
                        list={comapnyTyps}
                      />
                    </Grid>
                    {fields.map((field, index) => (
                      <Grid
                        key={field.id}
                        container
                        spacing={1}
                        columns={12}
                        p={3}
                      >
                        <Grid item xs={12}>
                          <StanderInput
                            name={`finance[${index}].finance_type`}
                            Label="type"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StanderInput
                            name={`finance[${index}].apr`}
                            Label="apr"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StanderInput
                            name={`finance[${index}].term`}
                            Label="term"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StanderInput
                            name={`finance[${index}].dealer_fees`}
                            Label="dealer fees"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          alignItems={"center"}
                          justifyContent="center"
                        >
                          <Button
                            color="error"
                            variant="outlined"
                            fullWidth
                            startIcon={
                              <Delete sx={{ fontSize: 30, color: "#fe5e5e" }} />
                            }
                            onClick={() => remove(index)}
                            sx={{ mt: 2 }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <Box>
                      <Button
                        startIcon={<ControlPoint />}
                        onClick={() =>
                          append({
                            apr: "",
                            finance_type: "",
                            term: "",
                            dealer_fees: "",
                          })
                        }
                        sx={{
                          color: "#00818A",
                          m: 2,
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        Add New Service
                      </Button>
                    </Box>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <AddButton type="submit" fullWidth>
                    Add Service
                  </AddButton>
                </Grid>
              </Grid>
            </SlidingPane>
          </form>
        </FormProvider>
      </BigSection>
    </div>
  );
};
const BigSection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
`;
export default AddServiceForm;
