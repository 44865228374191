import React from "react";
import styled from "styled-components";
import SlidingPane from "../../../common/slidingPane";
import { Grid } from "@mui/material";
import { MyInputLable, MyTextFiled } from "../../../common/standardSelect";
import { AddButton } from "../../../common/Buttons/AddButton";
import { UploadButton } from "../../../common/Buttons/UploadButton";
import { useCreateCompanyMutation } from "../../../../store/RTK/profile/company";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { Tlogin } from "../../../../typs/loginType";
import StanderInput from "../../../common/standerInput";
import Errors from "../../../common/Errors";
import MyGoogleMaps from "../../../common/googleMaps";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCompanyForm = ({ open, setOpen }: props) => {
  const methods = useForm<Tlogin>({ mode: "onChange" });
  const [createCompany, { isLoading, isError, isSuccess }] =
    useCreateCompanyMutation();
  const [errors, setError] = React.useState<any>("");
  // const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [photos, setPhotos] = React.useState({
    insurance_image: "",
    license_card_image: "",
  });
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   if (hiddenFileInput.current !== null) {
  //     hiddenFileInput.current.click();
  //   }
  // };
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: 0,
    lng: 0,
  });
  const photosHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (files !== null) {
      setPhotos({ ...photos, [name]: files[0] });
    }
  };
  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("slogan", data.slogan);
      formData.append("name", data.name);
      formData.append("address", address);
      formData.append("license_class", data.license_class);
      formData.append("license_type", data.license_type);
      formData.append("date_established", data.data_established);
      formData.append("state_license_link", data.state_license_link);
      formData.append("workmanship_warranty", data.workmanship_warranty);
      formData.append("insurance_image", photos.insurance_image);
      formData.append("insurance_expire_date", data.insurance_expire_date);
      formData.append("license_card_image", photos.license_card_image);
      formData.append(
        "license_card_expire_date",
        data.license_card_expire_date
      );
      let sendObj = {
        data: Object.fromEntries(formData),
      };
      await createCompany(formData).unwrap();
      if (!isError && !isLoading && isSuccess) {
        toast.success("Company has been created");
      }
    } catch (error) {
      setError(error);
    }
  };
  return (
    <BigSection>
      {" "}
      <FormProvider {...methods}>
        <SlidingPane open={open} setOpen={setOpen}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} className="line">
                <h3>Add company profile</h3>
              </Grid>
              <Grid item xs={12}>
                <StanderInput name="name" Label="Company name " required>
                  {" "}
                </StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput name="slogan" Label="Company slogan " required>
                  {" "}
                </StanderInput>
              </Grid>
              <Grid item xs={12}>
                <MyGoogleMaps
                  setCoordinates={setCoordinates}
                  address={address}
                  setAddress={setAddress}
                ></MyGoogleMaps>
                {/* <StanderInput
                  name="address"
                  required
                  Label="address"
                ></StanderInput> */}
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="license_class"
                  required
                  Label="license class"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="license_type"
                  required
                  Label="license type"
                ></StanderInput>
              </Grid>
              {/* <Grid item xs={12}>
                <UploadButton
                  variant="contained"
                  onClick={handleClick}
                  fullWidth
                >
                  Upload company logo
                </UploadButton>{" "}
                <input
                  type="file"
                  ref={hiddenFileInput}
                  // onChange={handleChange}
                  style={{ display: "none" }}
                ></input>
              </Grid> */}
              <Grid item xs={12}>
                <StanderInput
                  type="date"
                  name="data_established"
                  required
                  Label="data established"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="state_license_link"
                  required
                  Label="state license link"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="workmanship_warranty"
                  required
                  Label="workmanship warranty"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <MyInputLable>insurance image</MyInputLable>
                <MyTextFiled
                  type="file"
                  name="insurance_image"
                  onChange={photosHandleChange}
                  required
                  // label="insurance image"
                ></MyTextFiled>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  type="date"
                  name="insurance_expire_date"
                  required
                  Label="insurance expire date"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <MyInputLable>license_card_image</MyInputLable>
                <MyTextFiled
                  type="file"
                  name="license_card_image"
                  onChange={photosHandleChange}
                  required
                ></MyTextFiled>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  type="date"
                  name="license_card_expire_date"
                  required
                  Label="license_card_expire_date"
                ></StanderInput>
              </Grid>
              {/* <Grid item xs={12}>
                <UploadButton
                  variant="contained"
                  onClick={handleClick}
                  fullWidth
                >
                  Upload license photo
                </UploadButton>{" "}
                <input
                  type="file"
                  ref={hiddenFileInput}
                  // onChange={handleChange}
                  style={{ display: "none" }}
                ></input>
              </Grid> */}
              <Grid item xs={12}>
                <MyInputLable>License number </MyInputLable>
                <MyTextFiled fullWidth size="small"></MyTextFiled>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <AddButton fullWidth type="submit">
                  {isLoading ? (
                    <span className="loading"> creating...</span>
                  ) : (
                    <> Add Company Profile </>
                  )}
                </AddButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={errors?.data?.errors}></Errors>
              </Grid>
            </Grid>
          </form>
        </SlidingPane>
      </FormProvider>
    </BigSection>
  );
};
const BigSection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .drag-drop {
    display: flex;
    min-height: 5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 12px;
    padding: 16px 24px;
    width: 352px;
    height: 126px;
    background: #ffffff;
    border: 1px dashed #e4e7ec;
    border-radius: 8px;
    img {
      width: 40px;
      height: 40px;
    }
    p:nth-child(2) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #6941c6;
    }
  }
`;
export default AddCompanyForm;
