import React from "react";
import styled from "styled-components";
import { Table } from "../../../style/TableStyle";
import avatar from "../../../assets/Avatar.png";
import {
  Grid,
  CircularProgress,
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import { BigsectionTableContainer } from "../../reporting";
import SendInvitationForm from "./sendInvitation";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import {
  useAllInvitationsQuery,
  useChangeStatusMutation,
} from "../../../store/RTK/profile/invitations";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Edit, Pin } from "@mui/icons-material";

type teamTypes = {
  business_id: number;
  business_name: string;
  created_at: string;
  deleted_at: null | string;
  email: string;
  id: number;
  name: string;
  status: string;
  updated_at: string;
  user_id: number;
  user_type: string;
};

type TStatus = {
  label: string;
  color: "#F79009" | "#12B76A" | "#f2452e";
};

const statusView: Record<string, TStatus> = {
  pending: { label: "Pending", color: "#F79009" },
  approved: { label: "Approved", color: "#12B76A" },
  declined: { label: "declined", color: "#f2452e" },
};

const Invitations = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  let return_all = "1";
  const { data, isLoading } = useAllInvitationsQuery(return_all);
  const handlChange = () => {
    setOpen(!open);
  };

  const [changeStaus] = useChangeStatusMutation();

  const HandleStatus = (id: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openStatus = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (status: string) => {
      setAnchorEl(null);
      const data = {
        status: status,
      };

      console.log(data);
      changeStaus({ ...id, data });
    };
    return (
      <>
        <Button
          id="basic-button"
          aria-controls={openStatus ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openStatus ? "true" : undefined}
          onClick={handleClick}
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            // background: "#777",
            borderRadius: "16px",
          }}
        >
          Change Status
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openStatus}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleClose("approved")}>approved</MenuItem>
          <MenuItem onClick={() => handleClose("declined")}>declined</MenuItem>
        </Menu>
      </>
    );
  };
  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <div className="container">
            <div className="firstdiv">
              <div>
                <h4>Invitations</h4>
              </div>
              <div>
                {" "}
                <AddButton startIcon={<MyAddIcon />} onClick={handlChange}>
                  Send invitation
                </AddButton>
              </div>
            </div>

            {isLoading ? (
              <>
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    width: "100%",
                    height: "200px",
                    px: 15,
                  }}
                >
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <>
                {" "}
                {data.data.length > 0 ? (
                  <>
                    {" "}
                    <Grid container columns={12} spacing={5}>
                      {/* return ( */}
                      <Grid item xs={12}>
                        <div className="seconddiv">
                          <Table>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Business ID</th>
                                <th>Name</th>
                                <th>Business Name</th>
                                <th>Status</th>
                                <th>Role</th>
                                <th>Email address</th>
                                <th>Change Staus</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.map((e: teamTypes) => (
                                <tr>
                                  <td>{e.id}</td>
                                  <td>{e.business_id}</td>
                                  <td>{e.name}</td>
                                  <td>{e.business_name}</td>
                                  <td>
                                    {" "}
                                    <Chip
                                      sx={{
                                        bgcolor:
                                          statusView[`${e.status}`].color + 10,
                                        color: statusView[`${e.status}`].color,
                                        borderColor:
                                          statusView[`${e.status}`].color,
                                        fontWeight: 600,
                                      }}
                                      variant="outlined"
                                      label={e.status}
                                    />
                                  </td>
                                  <td>{e.user_type}</td>
                                  <td>{e.email}</td>
                                  <td>
                                    {" "}
                                    <HandleStatus id={e.id} />
                                  </td>
                                  <td>
                                    <IconButton>
                                      <Edit />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Grid>
                      {/* ); */}
                    </Grid>
                  </>
                ) : (
                  <h3 style={{ textAlign: "center" }}>No data</h3>
                )}
              </>
            )}
          </div>
        </Grid>
      </Bigsection>
      <SendInvitationForm open={open} setOpen={setOpen} />
    </>
  );
};

const MainButton = styled.button`
  width: 100%;
  padding: 10px 16px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-bottom: 0.4rem;
  cursor: pointer;
`;

const Bigsection = styled.div`
  .container {
    min-width: 100%;
  }
  .success,
  .pending {
    display: flex;
    align-items: center;
    justify-content: center;

    .dot {
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;

      margin-right: 8px;
    }
    .not {
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: red;
      margin-right: 8px;
    }
    p {
      background: #eaf6f1;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      font-size: 12px;
      line-height: 18px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
    }
  }
  .pending {
    color: #f79009;
    .dot {
      background-color: #f79009;
    }
    p {
      background: #fffaeb;
    }
  }
  td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #667085;
  }
  .img-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    img {
      width: 40px;
      height: 40px;
    }
    p:nth-child(1) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #101828;
    }
    p:nth-child(2) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #667085;
    }
  }
`;
export default Invitations;
