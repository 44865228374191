import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

type props = {
  open?: any;
  setOpen?: any;
  children: React.ReactNode;
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const MyModal: React.FC<props> = ({ open, setOpen, children }: props) => {
  const handleClose = () => setOpen(false);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            {" "}
            {children}
          </Typography>
        </Box>
      </Modal>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .buttons {
    display: flex;
  }
`;
export default MyModal;
