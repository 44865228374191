import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const addersApi = createApi({
  reducerPath: "adders",
  baseQuery,
  tagTypes: ["requiredAdders", "normalAdders", "all-addders"],
  endpoints: (builder) => ({
    getAddars: builder.query({
      query: ({ page, return_all, search }) => ({
        url: "/adders",
        method: "get",
        params: {
          page: page,
          return_all,
          search,
        },
      }),
      providesTags: ["all-addders"],
    }),

    // edit single adder
    editSingleAdder: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `adders/${id}/edit`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["all-addders"],
    }),

    // delete single adder

    deleteSingleAdder: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `adders/${id}/delete`,
        method: "POST",
      }),
      invalidatesTags: ["all-addders"],
    }),

    getRequiredAdders: builder.query({
      query: ({ status, return_all }) => ({
        url: `adders/requested`,
        method: "get",
        params: {
          return_all,
          status,
        },
      }),
      providesTags: ["requiredAdders"],
    }),
    //get all category
    getAllCategories: builder.query({
      query: () => ({
        url: "/categories/adders",
        method: "get",
        params: {
          return_all: 1,
        },
      }),
    }),

    getAllCategoriesChild: builder.query({
      query: ({ parent_id }) => ({
        url: "/categories/adders",
        method: "get",
        params: {
          return_all: 1,
          parent_id,
        },
      }),
    }),

    //add adders
    createAdder: builder.mutation<any, any>({
      query: (body) => ({
        url: "/adders/create-or-edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["all-addders"],
    }),
  }),
});

export const {
  useGetAddarsQuery,
  useGetRequiredAddersQuery,
  useGetAllCategoriesChildQuery,
  useCreateAdderMutation,
  useGetAllCategoriesQuery,
  useEditSingleAdderMutation,
  useDeleteSingleAdderMutation,
} = addersApi;
