import React from "react";
import styled from "styled-components";
import { Table } from "../../../style/TableStyle";
import avatar from "../../../assets/Avatar.png";
import { Grid , CircularProgress ,Box } from "@mui/material";
import { BigsectionTableContainer } from "../../reporting";
import AddTeamForm from "./addTeamForm";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import { useAllTeamQuery } from "../../../store/RTK/profile/team";
import DeleteTeam from "./deleteTeam";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

type teamTypes = {
  full_name: string;
  email: string;
  user_type: string;
  phone: string;
  is_belong_to_company: number;

};

const Team = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  let return_all = "1";
  const { data, isLoading } = useAllTeamQuery(return_all);
  const handlChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <div className="container">
            <div className="firstdiv">
              <div>
                <h4>Team members</h4>
              </div>
              {/* <div>
                {" "}
                <AddButton startIcon={<MyAddIcon />} onClick={handlChange}>
                  Add team member
                </AddButton>
              </div> */}
            </div>
         
                 {isLoading ? (
          <>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                width: "100%",
                height: "200px",
                px: 15,
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {" "}
            {data.data.length > 0 ? (
              <>
                {" "}
                <Grid container columns={12} spacing={5}>
             
                    {/* return ( */}
                      <Grid item xs={12}>
                      <div className="seconddiv">
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>belong to your Company ?</th>
                              <th>Role</th>
                              <th>Email address</th>
                              <th>phone</th>
                              <th>action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {data?.data?.map((e: teamTypes) => (
                            <tr>
                              <td>
                                <div className="img-container">
                                  <img src={avatar}></img>
                                  <div>
                                    <p>{e.full_name}</p>
                                  
                                  </div>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="success">
                                  <p>
                                  {e.is_belong_to_company == 1  ? ( <><span className="dot"></span>  <span>Yes</span></> ): (<><span className="not" ></span> <span>No</span></> )  }
                                  </p>
                                </div>
                              </td>
                              <td>{e.user_type}</td>
                              <td>{e.email}</td>
                              <td>{e.phone}</td>
                              <td>
                              <button
                                onClick={() => {
                                  setSelectedTeam(e);
                                  handleOpen();
                                }}
                              >
                              <DeleteForeverIcon /> 
                              </button>
                              </td>
                            </tr>
                          ))}
                          
                      
                           
                          </tbody>
                        </Table>
                      </div>
                      </Grid>
                    {/* ); */}
                
                </Grid>
              </>
            ) : (
              <h3 style={{ textAlign: "center" }}>No data</h3>
            )}
          </>
        )}
          </div>
        </Grid>
      </Bigsection>
      <AddTeamForm open={open} setOpen={setOpen} />

      <DeleteTeam
        open={openModal}
        setOpen={setOpenModal}
        selectedTeam={selectedTeam}
      ></DeleteTeam>
    </>
  );
};

const Bigsection = styled.div`

button {
  width: 100%;
  padding: 10px 16px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-bottom: 0.4rem;
  cursor: pointer;
}

  .container {
    min-width: 100%;
  }
  .success,
  .pending {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e7d59;
    .dot {
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #28a676;
      margin-right: 8px;
    }
    .not{
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: red;
      margin-right: 8px;
    }
    p {
      background: #eaf6f1;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      font-size: 12px;
      line-height: 18px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
    }
  
  }
  .pending {
    color: #f79009;
    .dot {
      background-color: #f79009;
    }
    p {
      background: #fffaeb;
    }
  }
  td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #667085;
  }
  .img-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    img {
      width: 40px;
      height: 40px;
    }
    p:nth-child(1) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #101828;
    }
    p:nth-child(2) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #667085;
    }

   
  }
`;
export default Team;
