import { Button } from "@mui/material";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
  && {
    background: #01545a;
    border-radius: 6.01115px;
    color: white;
    padding: 10.0186px 15.0279px;
    &:hover {
      background: #01545a;
      color: white;
    }
  }
`;
