import { Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AddButton } from "../../common/Buttons/AddButton";
import StanderInput from "../../common/standerInput";
import SelectInput from "../../common/SelectInput/SelectInput";
import { useGetAllCategoriesChildQuery } from "../../../store/RTK/adders";
import { useRequestNewAddersMutation } from "../../../store/RTK/requiredAdders";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  adder_name: yup.string().required(),
  description: yup.string().required(),
  parent_category_id: yup.string().required(),
});

const CreateRquest = () => {
  const { data } = useGetAllCategoriesChildQuery({});
  const categoryList = data?.data;

  const [createRquest, { isLoading }] = useRequestNewAddersMutation();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    createRquest(data)
      .unwrap()
      .then(() => {
        toast.success("Rquest created successfully");
      })
      .catch((err) => toast.error(err?.data?.message));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12} className="line">
            <h3>Create Rquest</h3>
          </Grid>

          <Grid item xs={12}>
            <StanderInput
              name="adder_name"
              Label="adder name"
              placeholder="adder_name"
            />
          </Grid>
          <Grid item xs={12}>
            <StanderInput
              name="description"
              Label="description"
              placeholder="description"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              name="parent_category_id"
              label="parent category"
              data={categoryList || []}
            />
          </Grid>

          <Grid item xs={12}>
            <AddButton type="submit" fullWidth>
              {isLoading ? "loading..." : "Request"}
            </AddButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CreateRquest;
