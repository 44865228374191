import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const newAddersApi = createApi({
  reducerPath: "newAddersApi",
  baseQuery,
  tagTypes: ["newAdders"],
  endpoints: (builder) => ({
    getAllnewAdders: builder.query({
      query: () => ({
        url: `adders-v2`,
        method: "get",
        params: {
          return_all: "1",
        },
      }),
      providesTags: ["newAdders"],
    }),
    getSingleNewAdder: builder.query({
      query: ({ id }) => ({
        url: `adders-v2/${id}`,
        method: "get",
      }),
      providesTags: ["newAdders"],
    }),
    //add adders
    createNewAdder: builder.mutation<any, any>({
      query: (body) => ({
        url: "adders-v2/create-or-edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["newAdders"],
    }),

    deleteNewAdder: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `adders-v2/${id}/delete`,
        method: "POST",
      }),
      invalidatesTags: ["newAdders"],
    }),
    editNewAdder: builder.mutation<any, any>({
      query: (body) => ({
        url: "adders-v2/create-or-edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["newAdders"],
    }),
  }),
});

export const {
  useGetAllnewAddersQuery,
  useGetSingleNewAdderQuery,
  useCreateNewAdderMutation,
  useDeleteNewAdderMutation,
  useEditNewAdderMutation,
} = newAddersApi;
