import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import AddServiceForm from "./addServiceForm";
import { useGetServicesQuery } from "../../../store/RTK/profile/services";
import ServiceTable from "./SeviceTable";

const Services = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetServicesQuery({
    page: 1,
  });

  const [open, setOpen] = React.useState<boolean>(false);

  const handlChange = () => {
    setOpen(!open);
  };
  return (
    <>
      <Bigsection>
        <Grid
          container
          columns={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Grid item>
            <p className="header">Services</p>
          </Grid>
          <Grid item>
            <AddButton
              startIcon={<MyAddIcon></MyAddIcon>}
              onClick={handlChange}
            >
              Add service
            </AddButton>
          </Grid>{" "}
        </Grid>
        <Box>
          <ServiceTable
            data={data}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
          />
        </Box>
      </Bigsection>

      {open && <AddServiceForm open={open} setOpen={setOpen} />}
    </>
  );
};
const Bigsection = styled.div`
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #01545a;
    width: 100%;
  }
  .license {
    background: #ffffff;
    .imgContainer {
      text-align: center;
      img {
        width: 100%;
      }
    }

    .info {
      .content {
        padding: 1rem 0.5rem;
        p {
          font-family: "Inter";
          font-style: normal;
          color: #667085;
          margin-bottom: 1rem;
          &:nth-child(1) {
            color: #01545a;
            font-weight: 600;
            font-size: 14px;
          }
          &:nth-child(2) {
            font-weight: 600;
            font-size: 20px;
            color: #101828;
          }
        }
        h4 {
        }
      }
      button {
        width: 100%;
        padding: 10px 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-bottom: 0.4rem;
        cursor: pointer;
      }
    }
  }
`;
export default Services;
