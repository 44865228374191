import React from "react";
import MyModal from "../../../common/Modal";
import styled from "styled-components";
import { useDeleteLicenseMutation } from "../../../../store/RTK/profile/liecense";
import { DeleteButton, NoButton } from "../../../common/Buttons/deleteButton";
type props = {
  open?: any;
  setOpen?: any;
  selectedLicense: {
    id: number;
    license_number: number;
    license_holder: string;
    license_type: string;
    address: string;
    expiration_date: string;
    lat: any;
    lng: any;
  };
};
const DeleteLiecese: React.FC<props> = ({
  open,
  setOpen,
  selectedLicense,
}: props) => {
  const [deleteLicense, { isLoading, isError, isSuccess }] =
    useDeleteLicenseMutation();
  const Delete = async () => {
    await deleteLicense(selectedLicense.id);
  };
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    handleClose();
  }, [isSuccess]);

  console.log("isLoading, isError, isSuccess", isLoading, isError, isSuccess);
  return (
    <Bigsection>
      <MyModal open={open} setOpen={setOpen}>
        <h5>Are You sure you want to delete this License</h5>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "1rem 0",
          }}
        >
          <DeleteButton onClick={Delete}>
            Yes, Delete {isLoading ? <>Deleting</> : <></>}
          </DeleteButton>{" "}
          <NoButton onClick={handleClose}>No</NoButton>
        </div>
      </MyModal>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .buttons {
    display: flex;
  }
`;
export default DeleteLiecese;
