import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useGetAllCategoriesChildQuery } from "../../../../store/RTK/adders";
import styled from "styled-components";
import { useState } from "react";
import ListAdderCard from "../ListAdderCard";
import DaynamicForm from "../DynamicForm";

const CreateDynamicAdder = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();
  const paramValue = searchParams.get("children");
  const { data } = useGetAllCategoriesChildQuery(
    { parent_id: id },
    {
      skip: !id,
    }
  );

  const categoryList = data?.data;

  const checkChildren = categoryList?.reduce(
    (sum: any, obj: any) => sum + Number(obj?.children_count),
    0
  );

  const handleRender = () => {
    if (checkChildren > 0 && paramValue === "no-children") {
      return <DaynamicForm data={categoryList} />;
    } else if (checkChildren > 0) {
      <ListAdderCard categoryList={categoryList} />;
    } else {
      return <DaynamicForm data={categoryList} />;
    }
  };

  return <>{handleRender()}</>;
};

export default CreateDynamicAdder;
