import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import SlidingPane from "../common/slidingPane";
import { Grid } from "@mui/material";
import StanderInput from "../common/standerInput";
import { AddButton } from "../common/Buttons/AddButton";
import Errors from "../common/Errors";
import { useEditProfileMutation } from "../../store/RTK/profile/profile";
import * as yup from "yup";
import SelectInput from "../common/SelectInput/SelectInput";
import { useCategoriesQuery } from "../../store/RTK/auth";
import UploadFile from "../common/uplodeFile";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import FileUpload from "../common/FileUploade";
import DateInput from "../common/DateInput";
import dayjs from "dayjs";

const schema = yup.object({
  name: yup.string().required("This field is requird"),
  email: yup.string().email("must be email").required("This field is requird"),
  company_email: yup
    .string()
    .email("must be email")
    .required("This field is requird"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required"),
  company_name: yup.string().required("This field is requird"),
  company_phone: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("This field is requird"),
  phone: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("This field is requird"),
  category: yup.string().required("This field is requird"),
});

const EditProfile = ({ data }: any) => {
  console.log("data", data);
  const [editProfile, { isLoading }] = useEditProfileMutation();

  const { data: listOfCategory } = useCategoriesQuery({});

  const listOfAllCategory = listOfCategory?.data?.map((item: any) => ({
    id: item.type,
    name: item.name,
  }));

  const methods = useForm<any>({ mode: "onChange" });

  const onSubmit = async (data: any) => {
    console.log("date", data.birthdate);
    const formData = new FormData();
    formData.append("image", data.image[0] as Blob);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("category", data.category);
    formData.append("birthdate", dayjs(data?.birthdate).format("YYYY-MM-DD"));
    formData.append("address", data.address);
    formData.append("personal_bio", data.personal_bio);
    formData.append("phone", data.phone);

    await editProfile(formData)
      .unwrap()
      .then((res: any) => {
        toast.success("Edit profile successfully ");
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
      });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12} className="line">
            <h3>Edit User</h3>
          </Grid>
          <Grid item xs={12} mt={3}>
            <StanderInput
              required
              Label="name"
              name="name"
              defaultValue={data?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <StanderInput
              required
              Label="email"
              name="email"
              defaultValue={data?.email}
            />
          </Grid>

          <Grid item xs={12}>
            <DateInput
              required
              label="birthdate"
              name="birthdate"
              defaultValue={data?.birthdate ? dayjs(data?.birthdate) : null}
            />
          </Grid>
          <Grid item xs={12}>
            <StanderInput
              required
              Label="address"
              name="address"
              defaultValue={data?.address}
            />
          </Grid>
          <Grid item xs={12}>
            <StanderInput
              required
              Label="personal bio"
              name="personal_bio"
              defaultValue={data?.personal_bio}
            />
          </Grid>
          <Grid item xs={12}>
            <StanderInput
              required
              Label="phone"
              name="phone"
              defaultValue={data?.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              name="category"
              label="category "
              data={listOfAllCategory}
              defaultValue={data?.category}
            />
          </Grid>

          <Grid item xs={12}>
            <FileUpload
              name="image"
              label="Upload Employee Image"
              accept="image/*"
              defaultValue={data?.image}
            />
          </Grid>

          <Grid item xs={12}>
            <AddButton type="submit" fullWidth>
              {" "}
              {isLoading ? "loading..." : "edit"}
            </AddButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditProfile;
