import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoginContainer, SignUpText } from "../../pages/login/loginPageStyle";
import { ButtonStyled } from "../common/Buttons/PrimaryButton";
import { useRegisterMutation } from "../../store/RTK/auth";
import styled from "styled-components";
import StepOne from "./StepOne";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import StepTwo from "./StepTwo";
import { Box } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

const schema = yup.object({
  name: yup.string().required("This field is requird"),
  email: yup.string().email("must be email").required("This field is requird"),
  company_email: yup
    .string()
    .email("must be email")
    .required("This field is requird"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm Password is required"),
  company_name: yup.string().required("This field is requird"),
  company_phone: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("This field is requird"),
  phone: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("This field is requird"),
  category: yup.string().required("This field is requird"),
  company_type: yup.string().required("This field is requird"),
});

const SignUpForm = ({ setIsLogin }: any) => {
  const navigate = useNavigate();

  //handle step
  const [step, setStep] = useState(1);
  const handleNext = () => setStep(step + 1);
  const handlePreve = () => setStep(step - 1);

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [signUp, { isLoading, isSuccess, error }] = useRegisterMutation();

  const onSubmit = async (data: any) => {
    console.log("data", data);
    const formData = new FormData();
    formData.append("logo", data.logo);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.password_confirmation);
    formData.append("category", data.category);
    formData.append("company_name", data.company_name);
    formData.append("company_phone", data.company_phone);
    formData.append("company_email", data.company_email);
    formData.append("company_type", data.company_type);

    await signUp(formData)
      .unwrap()
      .then((res: any) => {
        toast.success("created successfully");
        navigate("/");
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
      });
  };

  const renderActiveStepForm = (step: number) => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      default:
        break;
    }
  };

  return (
    <LoginContainer>
      <SignUpHeader>
        <h2>{step === 1 ? "Installer Information" : "Company Information"} </h2>
        <div>{step}/2</div>
      </SignUpHeader>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {renderActiveStepForm(step)}

          {step === 1 ? (
            <ButtonStyled onClick={handleNext}>Next</ButtonStyled>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <ButtonStyled sx={{ background: "#888" }} onClick={handlePreve}>
                Preve
              </ButtonStyled>

              <ButtonStyled type="submit">
                {isLoading ? "Loading..." : "Let's Go"}
              </ButtonStyled>
            </Box>
          )}
        </form>
      </FormProvider>
      <a href="">Forget Password ?</a>
      <SignUpText>
        you already have account{" "}
        <button onClick={() => setIsLogin(false)}> login </button>
      </SignUpText>
    </LoginContainer>
  );
};

const SignUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 3rem;

  h2 {
    color: #01545a;
    font-size: 2.2rem;
    font-weight: bold;
  }
  div {
    width: 50px;
    height: 50px;
    border: 2px solid #01545a;
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 1.3rem;
    color: #01545a;
    font-weight: bold;
  }
`;

export default SignUpForm;
