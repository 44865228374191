import React from "react";
import styled from "styled-components";
import { Table } from "../../../style/TableStyle";
import { Box, CircularProgress, Grid } from "@mui/material";
import { MyAddIcon, MyExpandMoreIcon } from "../../common/icons";
import AddWorkingDays from "./addWorkingDaysForm";
import { AddButton } from "../../common/Buttons/AddButton";
import { useAllwokingDaysQuery } from "../../../store/RTK/workingDays";

type DayData = {
  created_at: string;
  day: string;
  from: string;
  to: string;
  id: number;
  updated_at: string;
  user_id: number;
};
const WorkingDays = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { data, isLoading, isFetching } = useAllwokingDaysQuery({});
  console.log("data", data);
  const handlChange = () => {
    setOpen(!open);
  };
  const Convert = (time: any) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM " : " PM "; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };

  const diff_minutes = (dt2: any, dt1: any) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  return (
    <>
      <Bigsection>
        <Grid container columns={12}>
          <div className="container">
            <div className="firstdiv">
              <div>
                <h4>Working Days Info</h4>
              </div>
              <div>
                {" "}
                <AddButton startIcon={<MyAddIcon />} onClick={handlChange}>
                  Add working days
                </AddButton>
              </div>
            </div>
            <Grid item xs={12}>
              <div className="seconddiv">
                {isLoading || isFetching ? (
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      width: "100%",
                      height: "200px",
                      px: 15,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table>
                    <tr>
                      <th>Day</th>
                      <th>From</th>
                      <th>To</th>
                      {/* <th>Duration</th> */}
                      {/* <th>Actions</th> */}
                    </tr>
                    {data?.data?.map((day: DayData) => {
                      return (
                        <tr>
                          <td>{day?.day}</td>
                          <td>{Convert(day?.from)}</td>
                          <td>{Convert(day?.to)}</td>
                          {/* <td>
                            {diff_minutes(new Date(day.to), new Date(day.from))}
                          </td> */}
                        </tr>
                      );
                    })}
                  </Table>
                )}
              </div>
            </Grid>
          </div>
        </Grid>
      </Bigsection>
      <AddWorkingDays open={open} setOpen={setOpen} />
    </>
  );
};
const Bigsection = styled.div`
  min-height: 100dvh;
  .container {
    min-width: 100%;
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
`;
export default WorkingDays;
