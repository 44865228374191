import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth/authSlice";
import { authApi } from "./RTK/auth";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { calendarApi } from "./RTK/calender";
import { jobOrderApi } from "./RTK/jobOrder";
import { Licenses } from "./RTK/profile/liecense";
import { Team } from "./RTK/profile/team";
import { Invitations } from "./RTK/profile/invitations";
import { company } from "./RTK/profile/company";
import { addersApi } from "./RTK/adders";
import { Activities } from "./RTK/profile/activities";
import { WorkingDays } from "./RTK/workingDays";
import { requiredAddersApi } from "./RTK/requiredAdders";
import { newAddersApi } from "./RTK/newAdders";
import { profileApiSLice } from "./RTK/profile/profile";
import { portfolioApiSLice } from "./RTK/profile/portfolio";
import { servicesApiSLice } from "./RTK/profile/services";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [Licenses.reducerPath]: Licenses.reducer,
    [Team.reducerPath]: Team.reducer,
    [Invitations.reducerPath]: Invitations.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
    [jobOrderApi.reducerPath]: jobOrderApi.reducer,
    [company.reducerPath]: company.reducer,
    [addersApi.reducerPath]: addersApi.reducer,
    [Activities.reducerPath]: Activities.reducer,
    [WorkingDays.reducerPath]: WorkingDays.reducer,
    [requiredAddersApi.reducerPath]: requiredAddersApi.reducer,
    [newAddersApi.reducerPath]: newAddersApi.reducer,
    [profileApiSLice.reducerPath]: profileApiSLice.reducer,
    [portfolioApiSLice.reducerPath]: portfolioApiSLice.reducer,
    [servicesApiSLice.reducerPath]: servicesApiSLice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      Licenses.middleware,
      Team.middleware,
      Invitations.middleware,
      jobOrderApi.middleware,
      calendarApi.middleware,
      company.middleware,
      addersApi.middleware,
      Activities.middleware,
      WorkingDays.middleware,
      requiredAddersApi.middleware,
      newAddersApi.middleware,
      profileApiSLice.middleware,
      portfolioApiSLice.middleware,
      servicesApiSLice.middleware
    ),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
