import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { type } from "os";
import StanderInput from "../../common/standerInput/index";
import { Tlogin } from "../../../typs/loginType";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SubmitButton } from "../../common/Buttons/SubmitButton";
import SlidingPane from "../../common/slidingPane";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePostEventMutation } from "../../../store/RTK/calender";
import { toast } from "react-toastify";
import Errors from "../../common/Errors";
import { MenuItem } from "@mui/material";
import { useGetJobOrderQuery } from "../../../store/RTK/jobOrder";
import StanderTextArea from "../../common/standardTextArea";

type props = {
  open?: boolean;
  setOpen?: any;
};
const AddEventForm: React.FC<props> = ({ open, setOpen }) => {
  const schema = yup.object({
    from_date: yup.date().required("this date is required"),
    to_date: yup
      .date()
      .required("this date is required")
      .min(yup.ref("from_date"), "end date can't be before start date"),
    from_time: yup.string().required("Time is required"),
    to_time: yup.string().required("Time is required"),
    // .min(yup.ref("from_time"), "to time can't be before start time"),
    title: yup.string().required("Title is required"),
    customer_name: yup.string().required("This customer name is required"),
    customer_address: yup.string().required("Customer address is required"),
    description: yup.string().required("Description is required"),
    type: yup
      .string()
      .required("type is required")
      .oneOf(["event", "task", "reminder"]),
    order_id: yup.string().required("Order is required"),
  });

  const [postEvent, { isLoading, isSuccess, isError }] = usePostEventMutation(
    {}
  );
  let return_all = 1;
  const {
    data,
    isLoading: loading,
    isFetching: fetching,
  } = useGetJobOrderQuery({ return_all });
  const [error, setError] = React.useState([]);
  const workingJobList = data?.data || [];
  let eventTypes = [
    { name: "Event", value: "event" },
    { name: "Task", value: "task" },
    { name: "Reminder", value: "reminder" },
  ];
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  methods.watch(["order_id"]);
  let orderId = methods.getValues("order_id");
  const findOrderDetails = (id: number) => {
    return workingJobList.find((e: any) => e.id === id);
  };
  React.useEffect(() => {
    if (orderId) {
      methods.setValue(
        "customer_name",
        findOrderDetails(orderId).customer_name
      );
      methods.setValue(
        "customer_address",
        findOrderDetails(orderId).customer_address
      );
      methods.setValue(
        "customer_name",
        findOrderDetails(orderId).customer_name
      );
    }
  }, [orderId]);
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Event has been created");
      setError([]);
    }
  }, [isSuccess]);
  console.log("findOrderDetails()", findOrderDetails(orderId));
  const onSubmit = async (data: any) => {
    console.log("Send Date", data);
    let sendData = {
      customer_address: data.customer_address,
      customer_name: data.customer_name,
      description: data.description,
      from_date: new Date(data.from_date).toISOString().split("T")[0],
      to_date: new Date(data.to_date).toISOString().split("T")[0],
      from_time: data.from_time,
      to_time: data.to_time,
      title: data.title,
      type: data.type,
      order_id: data.order_id,
    };
    try {
      await postEvent({ ...sendData }).unwrap();
      if (isSuccess) {
        toast.success("Event has been created");
        setError([]);
      }
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
    console.log("Send Date", data);
    console.log("error error", error);
  };
  return (
    <SlidingPane open={open} setOpen={setOpen} big>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="container">
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Add Event</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  name="type"
                  placeholder="Type"
                  Label="Type"
                  type="string"
                  isSelect
                >
                  {eventTypes.map((select, i: number) => {
                    return (
                      <MenuItem key={i} value={select.value}>
                        {select.name}
                      </MenuItem>
                    );
                  })}
                </StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="title"
                  placeholder="Event Title"
                  Label="Event Title"
                  type="string"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="order_id"
                  placeholder="Order"
                  Label="Order"
                  type="string"
                  isSelect
                >
                  {workingJobList?.map((select: any, i: number) => {
                    return (
                      <MenuItem key={select.id} value={select.id}>
                        {select.customer_name}, {select.customer_phone}
                      </MenuItem>
                    );
                  })}
                </StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="customer_name"
                  placeholder="customer name"
                  Label="Customer"
                  type="string"
                  disabled
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="customer_address"
                  placeholder="customer address"
                  Label="Customer address"
                  type="string"
                  disabled
                ></StanderInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <StanderInput
                  // required
                  name="from_date"
                  placeholder="22/6/23"
                  Label="Form date"
                  type="date"
                ></StanderInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <StanderInput
                  // required
                  name="to_date"
                  placeholder="22/6/23"
                  Label="To date"
                  type="date"
                ></StanderInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <StanderInput
                  // required
                  name="from_time"
                  // placeholder="22/6/23"
                  Label="Form Time"
                  type="time"
                ></StanderInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <StanderInput
                  // required
                  name="to_time"
                  // placeholder="22/6/23"
                  Label="To time"
                  type="time"
                ></StanderInput>
              </Grid>

              <Grid item xs={12}>
                <StanderTextArea
                  // required
                  name="description"
                  // placeholder="description"
                  Label="Description"
                  type="text"
                  minRows={5}
                ></StanderTextArea>
              </Grid>
              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {isLoading ? "creating" : "Save"}
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </SlidingPane>
  );
};

export default AddEventForm;
