import React from "react";
import MyModal from "../../../common/Modal";
import styled from "styled-components";
import { useDeleteTeamMutation } from "../../../../store/RTK/profile/team";
import { DeleteButton, NoButton } from "../../../common/Buttons/deleteButton";
type props = {
  open?: any;
  setOpen?: any;
  selectedTeam: {
    id: number;
    full_name: string;
    email: string;
    user_type: string;
    phone: string;
  is_belong_to_company: number;
  };
};
const DeleteTeam: React.FC<props> = ({
  open,
  setOpen,
  selectedTeam,
}: props) => {
  const [deleteTeamMember, { isLoading, isError, isSuccess }] =
  useDeleteTeamMutation();
  const Delete = async () => {
    await deleteTeamMember(selectedTeam.id);
  };
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    handleClose();
  }, [isSuccess]);

  console.log("isLoading, isError, isSuccess", isLoading, isError, isSuccess);
  return (
    <Bigsection>
      <MyModal open={open} setOpen={setOpen}>
        <h5>Are You sure you want to delete this License</h5>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "1rem 0",
          }}
        >
          <DeleteButton onClick={Delete}>
            Yes, Delete {isLoading ? <>Deleting</> : <></>}
          </DeleteButton>{" "}
          <NoButton onClick={handleClose}>No</NoButton>
        </div>
      </MyModal>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .buttons {
    display: flex;
  }
`;
export default DeleteTeam;
