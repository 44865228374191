import React from "react";
import styled from "styled-components";
import SlidingPane from "../../../common/slidingPane";
import { Grid } from "@mui/material";

import { AddButton } from "../../../common/Buttons/AddButton";

import { FormProvider, useForm } from "react-hook-form";
import GoogleMapsInput from "../../../common/GoogleMapInput/GoogleMaps";
import StanderInput from "../../../common/standerInput";
import StanderTextArea from "../../../common/standardTextArea";
import FileUpload from "../../../common/FileUploade";
import DateInput from "../../../common/DateInput";
import {
  useAddPortfolioMutation,
  useEditPortfolioMutation,
} from "../../../../store/RTK/profile/portfolio";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import dayjs from "dayjs";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  PortflioData: any;
};

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  date: yup.date().required("Date is required"),
  description: yup.string().required("Description is required"),
  location: yup.string().required("Location is required"),
});

const Portflio = ({ open, setOpen, PortflioData }: props) => {
  const [mutate, { isLoading }] = useAddPortfolioMutation();
  const [edit, { isLoading: loading }] = useEditPortfolioMutation();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("location", data?.location);
    formData.append("date", dayjs(data?.date).format("YYYY-MM-DD"));
    data?.image.map((item: any, index: any) => {
      formData.append(`images[${index}]`, item);
    });

    if (PortflioData) {
      const editData = {
        id: PortflioData?.id,
        data: formData,
      };
      await edit(editData)
        .unwrap()
        .then(() => {
          toast.success("Created Successfully");
        })
        .catch((error) => toast.error(error?.data?.message))
        .finally(() => {
          reset({
            title: "",
            description: "",
            location: "",
            date: undefined,
          });
        });
    } else {
      await mutate(formData)
        .unwrap()
        .then(() => toast.success("Created Successfully"))
        .catch((error) => toast.error(error?.data?.message))
        .finally(() => {
          reset({
            title: "",
            description: "",
            location: "",
            date: undefined,
          });
        });
    }
  };

  return (
    <BigSection>
      {" "}
      <SlidingPane open={open} setOpen={setOpen}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} className="line">
                <h3>Add Portfolio</h3>
              </Grid>

              <Grid item xs={12}>
                <StanderInput
                  name="title"
                  Label="Title"
                  placeholder="title"
                  defaultValue={PortflioData?.title}
                />

                <DateInput
                  name="date"
                  label="Date"
                  defaultValue={dayjs(PortflioData?.date)}
                />

                <GoogleMapsInput
                  name="location"
                  label="location"
                  placeholder="enter your location "
                  defaultValue={PortflioData?.location}
                />
                <StanderTextArea
                  name="description"
                  Label="Description"
                  defaultValue={PortflioData?.description}
                  minRows={5}
                  placeholder="add any desc..."
                />
                <FileUpload
                  name="image"
                  label="Upload File"
                  accept="image/*"
                  defaultValue={PortflioData?.image}
                  multiple
                />
              </Grid>

              <Grid item xs={12}>
                <AddButton type="submit" fullWidth>
                  {isLoading || loading
                    ? "loading...."
                    : PortflioData
                    ? "edit"
                    : "Add Company Profile"}
                </AddButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SlidingPane>
    </BigSection>
  );
};
const BigSection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .drag-drop {
    display: flex;
    min-height: 5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 12px;
    padding: 16px 24px;
    width: 352px;
    height: 126px;
    background: #ffffff;
    border: 1px dashed #e4e7ec;
    border-radius: 8px;
    img {
      width: 40px;
      height: 40px;
    }
    p:nth-child(2) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #6941c6;
    }
  }
`;
export default Portflio;
