import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import CustomeHeader from "../CustomeHeader";
import { NavLink, Outlet } from "react-router-dom";
const Reporting = () => {
  let title = "Reports";
  let desc = "Manage your team members and their account permissions here.";
  return (
    <BigsectionTableContainer>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <CustomeHeader title={title} desc={desc}></CustomeHeader>
        </Grid>
        <Grid item xs={12}>
          <div className="reports-nav">
            <ul>
              <NavLink to="collections">
                <li>Collections</li>
              </NavLink>
              <NavLink to="jobs">
                <li>Jobs</li>
              </NavLink>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Outlet></Outlet>
        </Grid>
      </Grid>
    </BigsectionTableContainer>
  );
};
export const BigsectionTableContainer = styled.div`
  padding: 1rem 2rem;
  position: relative;
  width: 100%;
  .container {
    margin-top: 2.5rem;
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .reports-nav {
    ul {
      display: flex;
      list-style: none;
      flex-direction: row;
      padding: 1.5rem 0;
      li {
        padding-right: 20px;
        position: relative;
        transition: 0.3s all;
        &:after {
          position: absolute;
          content: "";
          background-color: #01545a;
          width: 85%;
          height: 3px;
          bottom: -1rem;
          left: 0;
          margin: 0 auto;
          transform: translate(0%, -50%);
          text-align: center;
          margin-right: 1rem;
          display: none;
        }
      }
      a.active {
        li {
          &:after {
            display: block;
          }
        }
      }
    }
  }
`;
export default Reporting;
