import styled from "styled-components";
import { darkMode, lightMode } from "../color paltte/colorPlate";

export const NavBarStyle = styled.div`
  img {
    width: 65%;
  }
  a {
    color: inherit;
    text-decoration: none;
    li {
      font-weight: 500;
      font-size: 16px;
      svg {
        font-size: 24px;
      }
    }
  }
  a.active {
    li {
      div:nth-child(1) {
        background-color: #e6f2f3;
        color: #006168;
        border-radius: 6px;
        background-size: 300px 100px;
        div {
          svg {
            path {
              color: #006168;
            }
          }
        }
      }
      span {
        color: #006168 !important;
      }
    }
  }
  .nav {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p,
    span {
      font-family: "Inter", sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      color: black !important;
    }

    li {
      div {
        color: #344054;
        border-radius: 6px;
        background-size: 300px 100px;
        img {
          fill: red !important;
        }
      }
      .icon {
        color: red !important;

        #document {
          svg {
            color: red !important;
            path {
              color: red !important;
            }
          }
        }
      }
    }
  }
  .des {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #667085 !important;
  }
  .header-open {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-close {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .profile-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    filter: none !important;
  }
  && {
    @media (min-width: 100px) {
      .css-2dum1v-MuiDrawer-docked {
        width: 0;
      }
    }
  }
  .css-bpnhhg {
    width: 0;
  }
`;
