import React, { useState } from "react";
import styled from "styled-components";
import CustomeHeader from "../CustomeHeader";
import { Avatar, Grid } from "@mui/material";
import { MyFiPhone } from "../common/icons";
import { NavLink, Outlet } from "react-router-dom";
import { BigsectionTableContainer } from "../reporting";

import SlidingPane from "../common/slidingPane";
import EditProfile from "./EditProfile";
import { useProfileQuery } from "../../store/RTK/profile/profile";

const Profile = () => {
  const [open, setOpen] = useState(false);

  const { data } = useProfileQuery({});

  const info = data?.data;

  let title = info?.name;

  let desc = "Manage your team members and their account permissions here.";
  return (
    <BigSection>
      <CustomeHeader title={title} desc={desc}></CustomeHeader>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="infoContainer">
            <div className="seconddiv">
              <table>
                <tr>
                  <td colSpan={4}>
                    <div className="contaienr">
                      <div className="first_div">
                        <Avatar
                          src={info?.image}
                          sx={{ width: 56, height: 56 }}
                          alt={title}
                        />
                        <div>
                          <p>{title}</p>
                          <p>
                            <b>Category </b> : {info?.category}
                          </p>
                          <p>
                            <b>Email </b> : {info?.email}
                          </p>
                          <p>
                            <span>
                              <MyFiPhone />
                              {info?.phone}
                            </span>{" "}
                            {/* <span>
                              <MyMapIcon /> 500 mi
                            </span> */}
                          </p>
                        </div>
                      </div>

                      <div>
                        <button onClick={() => setOpen(!open)}>
                          Edit Profile
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "20%" }}>
                    <p className="header">Birthday</p>
                    <p>{info?.birthdate}</p>
                  </td>
                  <td style={{ width: "40%" }}>
                    <p className="header">Address</p>
                    <p>{info?.address}</p>
                  </td>
                  <td style={{ width: "40%" }}>
                    <p className="header">Personal Bio</p>
                    <p>{info?.personal_bio}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="nav-list">
            <ul>
              <li>
                <NavLink to="licenses">
                  {" "}
                  <p> License(s)</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="company">
                  <p> Company</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="team">
                  <p> Team</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="invitations">
                  <p> Invitations</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="payment">
                  <p>Payment</p>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="reviews">
                  <p> Reviews</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="portfolio">
                  <p> Portfolio</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="services">
                  <p> Services</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="achievements">
                  <p> Achievements</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="challenges">
                  <p> Challenges</p>
                </NavLink>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Outlet></Outlet>
        </Grid>
      </Grid>
      <SlidingPane
        open={open}
        setOpen={setOpen}
        children={data ? <EditProfile data={data?.data} /> : null}
      />
    </BigSection>
  );
};
const BigSection = styled(BigsectionTableContainer)`
  padding: 1rem 2rem;
  overflow-x: auto;
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b0cacc;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 32px !important;
      line-height: 44px;
      color: #333333;
    }
  }

  .infoContainer {
    margin-top: 1rem;

    .first_div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      div:nth-child(2) {
        margin-left: 1rem;
        p {
          display: flex;
          margin-right: 18px;
          span {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
        }
        svg {
          color: #7a8494;
          margin-right: 8px;
        }
      }
    }
    .seconddiv {
      table {
        background: #ffffff;
        border-collapse: separate;
        width: 80%;
        border: 1px solid #eeeff1;
        border-radius: 16px;
        td {
          border-right: 1px solid #eeeff1;
          text-align: left;
          padding: 1rem;
          width: 100%;
          p {
            color: #344054;
            font-size: 14px;
          }
          .header {
            color: #7a8494;
            font-size: 14px;
          }
          svg {
            height: 13.29px;
            width: 13.29px;
            color: #474d56;
            margin-right: 12px;
          }
        }
        tr:first-child td {
          border: none;
          border-bottom: 1px solid #eeeff1;
        }
        tr:last-child {
          td:last-child {
            border: none;
          }
        }
      }
    }
    .contaienr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 0.5rem 1rem;
        background: #01545a;
        cursor: pointer;
        border-radius: 6.01115px;
        color: rgb(252, 252, 252) !important;
        font-size: 12.026px;
      }
      .css-umlx2d-MuiButtonBase-root-MuiButton-root.Mui-disabled {
        color: rgb(233 233 233);
      }
    }
  }
  .nav-list {
    margin: 1.5rem 0;
    ul {
      display: flex;
      list-style: none;
      /* justify-content: space-between; */
      position: relative;
      overflow-x: auto;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #667085;
        position: relative;
        cursor: pointer;
        margin-right: 1rem;
        p {
          padding: 0.8rem;
        }

        a.active {
          p {
            background: #e6eeef;
            color: #1e7d59;
          }
          &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            background: #1e7d59;
            height: 3px;
            width: 100%;
            z-index: 2;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background: #e4e7ec;
        height: 3px;
        width: 100%;
      }
    }
  }
  .nav-content {
    min-height: 10dvh;
  }
`;
export default Profile;
