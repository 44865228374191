import React from "react";
import styled from "styled-components";
import { MyCloseIcon } from "../icons";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  big?: boolean;
  bigger?: boolean;
  hundred?: boolean;
};
interface KeyboardEvent {
  enterKey: boolean;
}
const SlidingPane: React.FC<props> = ({
  open,
  setOpen,
  children,
  big,
  bigger,
  hundred,
}: props) => {
  const handlClose = () => {
    setOpen?.(!open);
    console.log("clicked");
  };
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      console.log("e", e.key);
    }
    // Do something
  });
  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setOpen?.(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
  return (
    <>
      <Bigsection open={open} big={big} bigger={bigger}>
        {children}
        <div className="Sliding_container">
          <div className="close-btn" onClick={handlClose}>
            <MyCloseIcon />
          </div>
        </div>
      </Bigsection>
    </>
  );
};
const Bigsection = styled.div<props>`
  min-height: 100dvh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  width: ${(props) => (props.bigger ? "70rem" : props.big ? "40rem" : "25rem")};
  width: ${(props) => (props.hundred ? "100%" : "")};

  position: fixed;
  top: 0;
  transition-property: right, opacity;
  transition-duration: 0.5s;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  min-height: 100dvh;
  z-index: 20;
  padding: 1rem 2rem;
  background-color: #ffffff;
  min-height: 100dvh;
  right: ${(props) => (props.open ? "0px" : "-1500px")};
  .Sliding_container {
    position: relative;
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .hr {
    visibility: hidden;
  }
  @media (max-width: 450px) {
    width: ${(props) => (props.big ? "92%" : "92%")};
  }

  @media (max-width: 1200px) {
    width: ${(props) => (props.bigger ? "92%" : props.big ? "92%" : "92%")};

    .hr {
      visibility: visible;
      margin: 2rem 0;
    }
  }

  max-height: 100%;
  overflow-y: scroll;
  justify-content: center;
  .loader {
    display: flex;
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }
`;

export default SlidingPane;
