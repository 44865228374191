import React from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetAttachmentsOfJobOrderQuery } from "../../../../store/RTK/jobOrder";


type attachments = {

  id: number;
  path:string

};
const Attachments = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetAttachmentsOfJobOrderQuery(id);
  console.log(
    data,
    "___________________________________________ attachments data"
  );
  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <Bisection>
      <>
        {isLoading ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
              height: "200px",
              px: 15,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <List>
                {data?.data?.length > 0 ? (
                  data?.data?.map((e: attachments) => (
                    <a href={e.path} target="_blank">
                    <ListItem key={e.id}>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="View File" />
                    </ListItem>
                    </a>
                  ))
                ) : (
                  <ListItem> no data </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        )}
      </>
    </Bisection>
  );
};
const Bisection = styled.div`
  padding: 1rem 0;
  .content {
    display: flex;
    gap: 1rem 0.5rem;
    div {
      position: relative;
      width: 100%;
      p:first-child {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #7a8494;
      }
      p:nth-child(2) {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #344054;
      }
      &:after {
        content: "";
        position: absolute;
        background: #e4e7ec;
        right: 0;
        top: 0;
        height: 100%;
        width: 2px;
      }
    }
  }
`;
export default Attachments;
