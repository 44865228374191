import { compnayTypeList } from "../../constant/List";
import FileUpload from "../common/FileUploade";
import SelectInput from "../common/SelectInput/SelectInput";
import StanderInput from "../common/standerInput";

const StepTwo = () => {
  return (
    <>
      <StanderInput name="company_name" Label="Company name" />
      <StanderInput name="company_phone" Label="Company Phone" />
      <StanderInput name="company_email" Label="Company Email" />
      <SelectInput
        name="company_type"
        label="Company type"
        data={compnayTypeList}
      />
      <FileUpload name="logo" label="Upload logo" accept="image/*" />
    </>
  );
};

export default StepTwo;
