export const allPrice = [
  { id: "material", name: "material" },
  { id: "labor", name: "labor" },
  { id: "both", name: "both" },
];

export const priceTyps = [
  { id: "epc_price", name: "Epc price" },
  { id: "subcontractor_price", name: "subcontractor price" },
];

export const roffingTypes = [
  { id: "1", name: "Tile relay" },
  { id: "2", name: "Roof overlay" },
  { id: "3", name: "Re roofing" },
];

export const allPer = [
  { id: "watt", name: "watt" },
  { id: "unit", name: "unit" },
  { id: "ft", name: "feet" },
  { id: "sq", name: "square" },
  { id: "fixed", name: "fixed" },
  { id: "mile", name: "mile" },
  { id: "panel", name: "panel" },
  { id: "array", name: "array" },
];

export const operatorsList = [
  { id: "greater_than", name: "Greater Than " },
  { id: "less_than", name: "Less Than" },
  { id: "equal", name: "Equal" },
];

export const panelsList = [
  { id: "none", name: "none" },
  { id: "panels", name: "Panels" },
  { id: "miles", name: "Miles" },
  { id: "arrays", name: "Arrays" },
  { id: "kwh", name: "kwh" },
  { id: "unit", name: "Unit" },
  { id: "watt", name: "Watt" },
  { id: "story", name: "Story" },
  { id: "deg", name: "Deg" },
  { id: "ft", name: "feet" },
  { id: "sq", name: "square" },
];

export const comapnyTyps = [
  { title: "Sunlight", value: "sunlight", id: "sunlight" },
  { title: "Goodleap", value: "good_leap", id: "good_leap" },
  { title: "Mosaic", value: "mosaic", id: "mosaic" },
];

export const compnayTypeList = [
  { name: "EPC", value: "epc", id: "epc" },
  {
    name: "Licensed Subcontractor",
    value: "licensed_subcontractor",
    id: "licensed_subcontractor",
  },
  { name: "Both", value: "both", id: "both" },
];
