import styled from "styled-components";
import { darkMode, lightMode } from "../color paltte/colorPlate";

export const CalendarStyle = styled.div`
  padding: 1rem 2rem;
  position: relative;
  /* overflow-x: hidden; */
  .content {
    /* padding: 1rem 0; */
    font-family: "Open Sans";
    background: #ffffff;
    border-radius: 14.0609px;
    border: none;
  }
  .rbc-month-header {
    background: #fafcfe;
    border-radius: 13.1235px 13.1235px 0px 0px;
    border: none !important;
  }
  .rbc-header {
    padding: 1rem 3rem;
    border: none;
    span {
      color: #172327;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .rbc-month-view {
    border: 0.937391px solid #e5eaef;
    border-radius: 14.0609px;
    border: none;
  }
  .rbc-btn-group {
    background: #fafcfe;
    border-radius: 7.49913px;
    padding: 0.3rem;

    button {
      background: #d9e5e6;
      border-radius: 7.49913px;
      padding: 0.5rem 1rem;
      margin: 0 0.1rem;
      cursor: pointer;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 13.1235px;
      color: #172327;
    }
  }
  .rbc-active {
    background: #fcfcfc !important;
    font-weight: 600;
    font-size: 13.1235px;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: #e9f3ff;
    border-radius: 7.49913px;
    color: #01545a;
  }
  .rbc-timeslot-group {
    /* border-left: none; */
  }
  .rbc-day-bg + .rbc-day-bg {
    /* border: none; */
  }
  .rbc-addons-dnd,
  .rbc-calendar {
    padding: 1rem 0;
    background: #ffffff;
    border-radius: 14.0609px;
  }
  .rbc-toolbar .rbc-toolbar-label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16.873px;
    color: #172327;
  }
  .Calnedar-nav {
    ul {
      display: flex;
      list-style: none;
      flex-direction: row;
      padding: 1.5rem 0;
      li {
        padding-right: 20px;
        position: relative;
        transition: 0.3s all;
        &:after {
          position: absolute;
          content: "";
          background-color: #01545a;
          width: 85%;
          height: 3px;
          bottom: -1rem;
          left: 0;
          margin: 0 auto;
          transform: translate(0%, -50%);
          text-align: center;
          margin-right: 1rem;
          display: none;
        }
      }
      a.active {
        li {
          &:after {
            display: block;
          }
        }
      }
    }
  }
`;
