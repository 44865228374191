import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const calendarApi = createApi({
  reducerPath: "calenderAPi",
  baseQuery,
  tagTypes: ["Calender"],
  endpoints: (builder) => ({
    //get all events
    getEvent: builder.query({
      query: () => "/calendar",
      providesTags: ["Calender"],
    }),
    getWorkingDays: builder.query({
      query: () => "/working-days",
      providesTags: ["Calender"],
    }),

    //create events
    postEvent: builder.mutation({
      query: (data) => ({
        url: "/calendar/create",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Calender"],
    }),

  }),
});

export const { useGetEventQuery, usePostEventMutation } = calendarApi;
