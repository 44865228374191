import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { type } from "os";
import StanderInput from "../../../common/standerInput/index";
import { Tlogin } from "../../../../typs/loginType";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SubmitButton } from "../../../common/Buttons/SubmitButton";
import SlidingPane from "../../../common/slidingPane";
import { MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import Errors from "../../../common/Errors";
import { usePostWorkingDaysMutation } from "../../../../store/RTK/workingDays";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddWorkingDays: React.FC<props> = ({ open, setOpen }) => {
  const methods = useForm<any>({ mode: "onChange" });
  const [postWorkingDays, { isLoading, isSuccess, isError }] =
    usePostWorkingDaysMutation();
  const [error, setError] = React.useState([]);
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("working days have been added");
      setError([]);
    }
  }, [isSuccess]);
  const onSubmit: SubmitHandler<Tlogin> = async (data) => {
    try {
      await postWorkingDays({ ...data }).unwrap();
      if (isSuccess) {
        toast.success("Event has been created");
        setError([]);
      }
    } catch (error: any) {
      toast.error("something went wrong");
      setError(error?.data?.errors);
    }
  };
  let weekDays = [
    { name: "Monday", value: "Monday" },
    { name: "TuesDay", value: "TuesDay" },
    { name: "Wednesday", value: "Wednesday" },
    { name: "Thursday", value: "Thursday" },
    { name: "Friday", value: "Friday" },
    { name: "Saturday", value: "Saturday" },
    { name: "Sunday", value: "Sunday" },
  ];
  return (
    <SlidingPane open={open} setOpen={setOpen}>
      <div className="container">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={2.5}>
              <Grid item xs={12}>
                <h4>Add Working days</h4>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  required
                  name="day"
                  placeholder="Select days"
                  Label="Working day"
                  control={methods.control}
                  isSelect
                >
                  {weekDays?.map((day) => {
                    return <MenuItem value={day.value}>{day.name}</MenuItem>;
                  })}
                </StanderInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <StanderInput
                  required
                  name="from"
                  // placeholder="22/6/23"
                  Label="Form Time"
                  type="time"
                ></StanderInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <StanderInput
                  required
                  name="to"
                  // placeholder="22/6/23"
                  Label="To time"
                  type="time"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <SubmitButton fullWidth type="submit">
                  Save {isLoading ? "submiting..." : ""}
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </SlidingPane>
  );
};
export default AddWorkingDays;
