import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const Invitations = createApi({
  reducerPath: "invitations",
  baseQuery,
  tagTypes: ["invitations"],
  endpoints: (builder) => ({
    allInvitations: builder.query({
      query: (return_all) => ({
        url: "/company-profiles/invitations",
        method: "GET",
        params: {
          return_all: return_all,
        },
      }),
      providesTags: ["invitations"],
    }),

    changeStatus: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `/network-connections/${id}/change-status`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["invitations"],
    }),

    sendTeamInvitation: builder.mutation<any, any>({
      query: (body) => ({
        url: "/company-profiles/send-invitation",
        method: "POST",
        body,
      }),
      invalidatesTags: ["invitations"],
    }),
  }),
});

export const {
  useSendTeamInvitationMutation,
  useAllInvitationsQuery,
  useChangeStatusMutation,
} = Invitations;
