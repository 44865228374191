import React, { useState } from "react";
import styled from "styled-components";
import CustomeHeader from "../CustomeHeader";
import { Box, CircularProgress, Grid, Pagination, Stack } from "@mui/material";
import { Table } from "../../style/TableStyle";
import { AddButton } from "../common/Buttons/AddButton";
import { MyAddIcon } from "../common/icons";
import WorkOrderDetails from "./workOrderDetails";
import ChangeWorkorder from "./changeWorkorder";
import { useGetJobOrderQuery } from "../../store/RTK/jobOrder";
import { Navigate, useNavigate } from "react-router-dom";

const JobsOrders = () => {
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetJobOrderQuery({ page });
  const navigate = useNavigate();

  const workingJobList = data?.data?.data;

  const workingList = () => {
    return workingJobList?.map((item: any) => (
      <tr key={item.id}>
        <td>{item?.customer_name}</td>
        <td>{item?.customer_address}</td>
        <td>{item?.pm_name}</td>
        <td>{item?.pm_email}</td>
        <td>{item?.pm_phone}</td>
        <td>
          <button onClick={() => handleChange(item?.id)}>details</button>
          {/* <button onClick={handleChangeChange}>change</button> */}
        </td>{" "}
      </tr>
    ));
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const [id, setId] = useState<string | number>("");

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  console.log(id);

  const [openChangeOrder, setOpenChangeOrder] = React.useState<boolean>(false);
  let title = "Job Order";
  let desc = "Manage your team members and their account permissions here.";
  const handleChange = (id: string | number) => {
    navigate(`/home/jobs-orders/work_Order/${id}`);
    setOpen(!open);
    setId(id);
  };
  const handleChangeChange = () => {
    setOpenChangeOrder(!openChangeOrder);
  };
  return (
    <>
      <Bigsection>
        <CustomeHeader title={title} desc={desc}></CustomeHeader>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <div className="container">
              <div className="firstdiv">
                <div>
                  <h4>Orders</h4>
                </div>
              </div>
              <div className="seconddiv"></div>

              {isLoading || isFetching ? (
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    width: "100%",
                    height: "200px",
                    px: 15,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Table>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>PM name</th>
                    <th>PM email</th>
                    <th>PM phone</th>
                    <th>Actions</th>
                  </tr>
                  {workingList()}
                </Table>
              )}
            </div>
          </Grid>

          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: 5,

              justifyContent: "flex-end",
            }}
          >
            <Pagination
              count={Math.ceil(data?.data?.total / 10)}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </Grid>
      </Bigsection>
      {open && <WorkOrderDetails id={id} open={open} setOpen={setOpen} />}
      <ChangeWorkorder open={openChangeOrder} setOpen={setOpenChangeOrder} />
    </>
  );
};
const Bigsection = styled.div`
  padding: 1rem 2rem;
  .container {
    margin-top: 2.5rem;
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  td,
  th {
    font-size: 12px;
  }
  button {
    background-color: #01545a;
    color: white;
    padding: 0.5rem;
    border: none;
    margin: 0 0.1rem;
    cursor: pointer;
  }
`;
export default JobsOrders;
