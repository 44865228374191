import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";

type props = {
  title?: string;
  desc?: string;
};
const CustomeHeader = ({ title, desc }: props) => {
  return (
    <BigSection>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <h3>{title}</h3>
        </Grid>
        <Grid item xs={12}>
          <p>{desc}</p>
        </Grid>
      </Grid>
    </BigSection>
  );
};
const BigSection = styled.div`
  /* padding: 1.5rem 0; */
  h3 {
    color: #101828;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #667085;
  }
`;

export default CustomeHeader;
