"use strict";

import React, { useEffect } from "react";
import SlidingPane from "../../../../common/slidingPane";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { Tlogin } from "../../../../../typs/loginType";
import {
  useEditAdderMutation,
  useGetAddersOfJobOrderQuery,
} from "../../../../../store/RTK/jobOrder";
import { Box, CircularProgress } from "@mui/material";
import { Table } from "../../../../../style/TableStyle";
import {
  MyAddIcon,
  MyCiEdit,
  MyRiDeleteBinLine,
} from "../../../../common/icons";
import EditAdderForm from "../editAdderForm";
import { AddButton } from "../../../../common/Buttons/AddButton";
import { StyledTextarea } from "../../../../common/standardSelect";
import { toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | null | number;
};
const DeleteAdders = ({ open, setOpen, id }: props) => {
  //get details
  const { data, isLoading, isFetching } = useGetAddersOfJobOrderQuery(id);
  const AddersDetails = data?.data || [];
  const [
    editAdder,
    {
      isLoading: loadingEditing,
      isSuccess: sucessEditing,
      isError: editingError,
    },
  ] = useEditAdderMutation();
  const [error, setError] = React.useState("");
  const [description, setDescription] = React.useState<string>("");

  const [sendEditAdders, setEditAdders] = React.useState<any>([]);

  const checkValue = (event: any) => {
    var updatedList = [...sendEditAdders];
    let foundId;
    if (event.target.checked) {
      foundId = sendEditAdders.some((obj: any) => {
        return obj.id === event.target.value;
      });
      if (foundId) {
      } else {
        updatedList = [...sendEditAdders, { id: event.target.value }];
      }
    } else {
      updatedList.splice(
        sendEditAdders.indexOf(
          sendEditAdders.find((e: any) => e.id === event.target.value)
        ),
        1
      );
    }
    setEditAdders(updatedList);
  };

  const UpdateValue = (event: any, adder_id: any) => {
    const { name, value } = event.target;
    const newArr = sendEditAdders.map((obj: any) => {
      console.log("check this", obj.id, adder_id.id);
      if (Number(obj.id) === adder_id.id) {
        return { ...obj, [name]: value };
      }
      return obj;
    });
    setEditAdders(newArr);
  };

  const deleteValue = (event: any, adder_id: any) => {
    var updatedList = [...sendEditAdders];
    let foundId;
    if (event.target.checked) {
      foundId = sendEditAdders.some((obj: any) => {
        return obj.id === event.target.value;
      });
      if (foundId) {
        const newArr = sendEditAdders.map((obj: any) => {
          console.log("check thisssss", obj.id, event.target.value);
          if (Number(obj.id) === Number(event.target.value)) {
            return { ...obj, updated_price: null };
          }
          return obj;
        });
        setEditAdders(newArr);
      } else {
        updatedList = [...sendEditAdders, { id: event.target.value }];
      }
    } else {
      updatedList.splice(
        sendEditAdders.indexOf(
          sendEditAdders.find((e: any) => e.id === event.target.value)
        ),
        1
      );
    }
    setEditAdders(updatedList);
  };

  useEffect(() => {
    if (!editingError && !loadingEditing && sucessEditing) {
      toast.success("Editing has been done");
      setEditAdders([]);
    }
  }, [editingError, loadingEditing, sucessEditing]);
  const handleEditAdders = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    sendEditAdders.map((e: any, i: number) => {
      formData.append(`prices[${i}][id]`, e.id);
      formData.append(`prices[${i}][updated_price]`, e.updated_price);
      formData.append(`remove[${i}]`, e.remove);
      formData.append(`description`, description);
    });
    try {
      let sendObj = {
        data: Object.fromEntries(formData),
        id: id,
      };
      await editAdder(sendObj);
    } catch (e: any) {
      setError(e);
    }
  };
  console.log("sendEditAdders", sendEditAdders);
  console.log("loadingEditing", loadingEditing);
  console.log("sucessEditing", sucessEditing);
  console.log("editingError", editingError);
  const workingList = () => {
    return AddersDetails?.map((item: any) => (
      <tr key={item.id}>
        <td>{item?.adder_name}</td>
        <td>{item?.adder_price} $</td>
        <td>
          <input
            style={{ width: "100px" }}
            type="number"
            name="updated_price"
            placeholder="new price"
            onChange={(e) => {
              UpdateValue(e, item);
            }}
            disabled={
              sendEditAdders.find((e: any) => Number(e.id) === item.id)
                ? false
                : true
            }
          ></input>
        </td>
        <td>
          {" "}
          <td>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox value={item.id} onChange={checkValue} />}
                label="Edit "
              />
              {/* <FormControlLabel
                control={<Checkbox value={item.id} onChange={deleteValue} />}
                label="Remove"
              /> */}
            </FormGroup>
            {/* <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={item}
                  control={<Radio />}
                  label="Edit"
                />
                <FormControlLabel
                  value={item}
                  control={<Radio  onChange={}/>}
                  label="Remove"
                />
              </RadioGroup>
            </FormControl> */}
          </td>
        </td>
      </tr>
    ));
  };

  return (
    <>
      {" "}
      <Bigsection>
        <form onSubmit={handleEditAdders}>
          <SlidingPane open={open} setOpen={setOpen} bigger={true}>
            <Grid container columns={12}>
              <Grid item xs={12} className="line">
                <h3>Change work order </h3>
              </Grid>

              <div className="container">
                <div className="firstdiv">
                  <div>
                    <h4>Adders rate</h4>
                  </div>
                </div>
                <div className="seconddiv"></div>

                {isLoading || isFetching ? (
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      width: "100%",
                      height: "200px",
                      px: 15,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Table>
                    <tr>
                      <th>Adders</th>
                      <th>Price</th>
                      <th>new Price</th>
                      <th>Actions</th>
                    </tr>
                    {workingList()}
                  </Table>
                )}
              </div>
              <Grid item xs={12}>
                <StyledTextarea
                  // type="text"
                  name="description"
                  placeholder="description"
                  minRows={5}
                  cols={30}
                  style={{ width: "100%", resize: "none", margin: "1rem 0" }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></StyledTextarea>
              </Grid>
              <Grid item xs={12}>
                <AddButton
                  fullWidth
                  type="submit"
                  // onClick={handleEditAdders}
                  endIcon={<MyCiEdit />}
                >
                  Edit Adders{" "}
                  {loadingEditing ? (
                    <p style={{ margin: "0 1rem" }}>Loading...</p>
                  ) : (
                    ""
                  )}
                </AddButton>
              </Grid>
            </Grid>
          </SlidingPane>
        </form>
      </Bigsection>
    </>
  );
};
const Bigsection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  svg {
    cursor: pointer;
  }
  .container {
    /* max-width: 550px; */
    width: 100%;
    margin-top: 25px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
    p {
      display: flex;
      align-items: center;
      margin-right: 15px;
      color: #99a5b9;
      background: #fafcfe;
      &:first-child {
        color: #1e7d59;
        background: #eaf6f1;
        border-radius: 4px;
        padding: 0px 8px;
      }
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b0cacc;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #333333;
    }
  }
  .infoContainer {
    .first_div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      div:nth-child(2) {
        margin-left: 1rem;
        p {
          display: flex;
          /* align-items: flex-end; */
          /* justify-content: flex-start; */
          margin-right: 18px;
          span {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
        }
        svg {
          color: #7a8494;
          margin-right: 8px;
        }
      }
    }
    .seconddiv {
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
        border: 1px solid #eeeff1;
        border-radius: 8px;
        padding: 1rem;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        width: 100%;
      }
      td {
        p {
          color: #344054;
          font-size: 14px;
        }
        .header {
          color: #7a8494;
          font-size: 14px;
        }
        svg {
          height: 13.29px;
          width: 13.29px;
          color: #474d56;
          margin-right: 12px;
        }
      }
    }
  }
  .nav-list {
    /* margin: 1.5rem 0; */
    ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
      position: relative;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #667085;
        position: relative;
        p {
          padding: 0.5rem;
        }

        a.active {
          p {
            background: #eaf6f1;
            color: #1e7d59;
          }
          &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            background: #1e7d59;
            height: 3px;
            width: 100%;
            z-index: 2;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background: #e4e7ec;
        height: 3px;
        width: 100%;
      }
    }
  }
  .nav-content {
    min-height: 10dvh;
  }
  .maps {
    width: 100%;
    height: 100%;
  }
`;
export default DeleteAdders;
