import React from "react";
import SlidingPane from "../../../common/slidingPane";
import styled from "styled-components";
import { Grid } from "@mui/material";
import StanderInput from "../../../common/standerInput";
import { MyTextFiled } from "../../../common/standardSelect";
import { MyInputLable } from "../../../common/standardSelect";
import { AddButton } from "../../../common/Buttons/AddButton";
type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddPayment = ({ open, setOpen }: props) => {
  return (
    <Bigsection>
      <SlidingPane open={open} setOpen={setOpen}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12} className="line">
            <h3>Banking info</h3>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Full name</MyInputLable>
            <MyTextFiled size="small" fullWidth></MyTextFiled>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Bank name</MyInputLable>
            <MyTextFiled size="small" fullWidth></MyTextFiled>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Branch address </MyInputLable>
            <MyTextFiled size="small" fullWidth></MyTextFiled>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Account number</MyInputLable>
            <MyTextFiled size="small" fullWidth></MyTextFiled>
          </Grid>
          <Grid item xs={12}>
            <MyInputLable>Bank code</MyInputLable>
            <MyTextFiled size="small" fullWidth></MyTextFiled>
          </Grid>
          <Grid item xs={12}>
            <AddButton fullWidth>Add banking info</AddButton>
          </Grid>
        </Grid>
      </SlidingPane>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  background: #ffffff !important;
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #00818a;
  }
  .css-u4tvz2-MuiFormLabel-root.Mui-focused {
    color: #101828;
  }
`;
export default AddPayment;
