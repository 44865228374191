import React from "react";
import SlidingPane from "../../common/slidingPane";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { AddButton } from "../../common/Buttons/AddButton";
import {
  MyAddIcon,
  MyBiCheck,
  MyCiMail,
  MyFiPhone,
  MyMapIcon,
  MyRiDeleteBinLine,
} from "../../common/icons";
import { NavLink, Outlet } from "react-router-dom";
import maps from "../../../assets/vector-maps.png";
import {
  useApproveJobOrderMutation,
  useGetSingleJobOrderQuery,
} from "../../../store/RTK/jobOrder";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditAdders from "../workOrderDetails/workOrder/editAdders";
import DeleteAdders from "./workOrder/deleteAdders";
type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | null | number;
};
const WorkOrderDetails = ({ open, setOpen, id }: props) => {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  //get details
  const { data, isLoading, isFetching } = useGetSingleJobOrderQuery(id);
  const details: any = data?.data;
  console.log(details, "detailsdetails");

  //add aprove
  const aprrovelData = {
    approval: "approved",
  };
  const [approve, { isLoading: isUpdating }] = useApproveJobOrderMutation();
  const handleEditadders = () => {
    setOpenEdit(!openEdit);
  };
  const handleDeleteadders = () => {
    setOpenDelete(!openDelete);
  };
  return (
    <Bigsection>
      <SlidingPane open={open} setOpen={setOpen} big={true}>
        <Grid container columns={12}>
          <Grid item xs={12} className="line">
            <h3>Work Order Details</h3>
          </Grid>

          {isFetching || isLoading ? (
            <Box py={5}>
              <Skeleton
                sx={{ mb: 2 }}
                variant="circular"
                width={60}
                height={60}
              />

              <Skeleton
                sx={{ mb: 2 }}
                variant="rounded"
                width={550}
                height={80}
              />

              <Skeleton
                sx={{ mb: 2 }}
                variant="rounded"
                width={550}
                height={40}
              />

              <Skeleton
                sx={{ mb: 2 }}
                variant="rounded"
                width={550}
                height={60}
              />
            </Box>
          ) : (
            <>
              <Grid item xs={12} mb={3}>
                <div
                  className={
                    details?.approval === "status pending"
                      ? "status pending"
                      : "status sucess"
                  }
                >
                  <p>
                    <MyBiCheck />
                    {details?.approval}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} mb={3}>
                <div className="infoContainer">
                  {/* <div className="first_div">
                <div className="name">
                  <p>EH</p>
                </div>
                <div>
                  <p>Esther Howard</p>
                  <p>
                    <span>
                      {" "}
                      <MyFiPhone />
                      702.555.0122
                    </span>{" "}
                    <span>
                      <MyMapIcon /> 500 mi
                    </span>
                  </p>
                </div>
              </div> */}
                  <div className="seconddiv">
                    <table>
                      <tr>
                        <td colSpan={4}>
                          <div className="first_div">
                            <Avatar
                              sx={{
                                background: "#b0cacc",
                                width: 60,
                                height: 60,

                                color: "#344054",
                              }}
                            >
                              {details?.customer_name.slice(0, 2)}
                            </Avatar>
                            <div>
                              <p>{details?.customer_name}</p>
                              <p>
                                <span>
                                  {" "}
                                  <MyFiPhone />
                                  {details?.customer_phone}
                                </span>{" "}
                                <span>
                                  <MyMapIcon /> 500 mi
                                </span>
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p className="header">Address</p>
                          <p> {details?.customer_address}</p>
                        </td>
                        <td style={{ width: "10%" }}>
                          <p className="header">State</p>
                          <p>{details?.state}</p>
                        </td>
                        <td style={{ width: "10%" }}>
                          <p className="header">County</p>
                          <p>{details?.county}</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="header">PM:{details?.pm_name} </p>
                          <p>
                            {" "}
                            <a href={`tel:${details?.pm_phone}`}>
                              <MyFiPhone />
                            </a>
                            <a href={`mailto:${details?.pm_email}`}></a>
                            <MyCiMail />
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} mb={3}>
                <img src={maps} alt="" className="maps" />
              </Grid>
              <Grid item xs={12} mb={3}>
                <div className="nav-list">
                  <ul>
                    <li>
                      <NavLink to={`work_Order/${id}`}>
                        <p> Work Order</p>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to={`activities/${id}`}>
                        <p> Activities</p>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to={`attachments/${id}`}>
                        <p> Attachments</p>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to={`time Frame/${id}`}>
                        <p> Time Frame</p>
                      </NavLink>
                    </li>

                    <li>
                      <IconButton onClick={handleEditadders}>
                        <EditIcon />
                      </IconButton>
                    </li>
                    {/* <li>
                      <IconButton onClick={handleDeleteadders}>
                        <MyRiDeleteBinLine />
                      </IconButton>
                    </li> */}
                  </ul>
                </div>
                <div className="nav-content">
                  <Outlet></Outlet>
                </div>
              </Grid>
              <Grid item xs={12} mb={3}>
                <AddButton
                  onClick={() => approve({ id, data: aprrovelData })}
                  fullWidth
                  disabled={details?.approval === "approved" ? true : false}
                  startIcon={
                    isUpdating ? (
                      <CircularProgress sx={{ width: 30, height: 30 }} />
                    ) : (
                      <MyBiCheck />
                    )
                  }
                  sx={{ padding: "0.8rem" }}
                >
                  Accept (Expected Earning $700){" "}
                </AddButton>
              </Grid>
            </>
          )}
        </Grid>
      </SlidingPane>
      {openEdit && <EditAdders id={id} open={openEdit} setOpen={setOpenEdit} />}
      {openEdit && (
        <DeleteAdders id={id} open={openDelete} setOpen={setOpenDelete} />
      )}
    </Bigsection>
  );
};
const Bigsection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;
    p {
      display: flex;
      align-items: center;
      margin-right: 15px;
      color: #99a5b9;
      background: #fafcfe;
      .sucess {
        color: #1e7d59;
        background: #eaf6f1;
        border-radius: 4px;
        padding: 0px 8px;
      }
      .pending {
        color: #f79009;
        .dot {
          background-color: #f79009;
        }
        p {
          background: #fffaeb;
        }
      }
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b0cacc;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: #333333;
    }
  }
  .infoContainer {
    .first_div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      div:nth-child(2) {
        margin-left: 1rem;
        p {
          display: flex;
          /* align-items: flex-end; */
          /* justify-content: flex-start; */
          margin-right: 18px;
          span {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
        }
        svg {
          color: #7a8494;
          margin-right: 8px;
        }
      }
    }
    .seconddiv {
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
        border: 1px solid #eeeff1;
        border-radius: 8px;
        padding: 1rem;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        width: 100%;
      }
      td {
        p {
          color: #344054;
          font-size: 14px;
        }
        .header {
          color: #7a8494;
          font-size: 14px;
        }
        svg {
          height: 13.29px;
          width: 13.29px;
          color: #474d56;
          margin-right: 12px;
        }
      }
    }
  }
  .nav-list {
    /* margin: 1.5rem 0; */
    ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
      position: relative;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #667085;
        position: relative;
        p {
          padding: 0.5rem;
        }

        a.active {
          p {
            background: #eaf6f1;
            color: #1e7d59;
          }
          &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            background: #1e7d59;
            height: 3px;
            width: 100%;
            z-index: 2;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background: #e4e7ec;
        height: 3px;
        width: 100%;
      }
    }
  }
  .nav-content {
    min-height: 10dvh;
  }
  .maps {
    width: 100%;
    height: 100%;
  }
`;
export default WorkOrderDetails;
