import React, { useState } from "react";
import styled from "styled-components";
import AddAddersForm from "../addAddersForm";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material";
import { useGetRequiredAddersQuery } from "../../../store/RTK/requiredAdders";
import { AddButton } from "../../common/Buttons/AddButton";
import { MyAddIcon } from "../../common/icons";
import { Table } from "../../../style/TableStyle";
import { StadardSelect } from "../../common/standardSelect";
import SlidingPane from "../../common/slidingPane";
import CreateRquest from "./CreateRquest";
const RequiredAdders = () => {
  const [open, setOpen] = useState(false);

  let return_all = "1";
  const [status, setStatus] = React.useState("pending");

  const { data, isFetching, isLoading } = useGetRequiredAddersQuery({
    status,
    return_all,
  });

  let allStatus = [
    { name: "pending", value: "pending" },
    { name: "Approved", value: "approved" },
    { name: "Declined", value: "declined" },
  ];

  const adderList = () => {
    return data?.data?.map((item: any) => (
      <tr key={item.id}>
        <td>{item?.id}</td>
        <td>{item?.installer_name}</td>
        <td>{item?.adder_name}</td>
        <td>{item?.description}</td>
      </tr>
    ));
  };
  return (
    <Bigsection>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="container">
            <div className="firstdiv">
              <div className="filter">
                <h4>Requested rate</h4>
                <div>
                  <StadardSelect
                    value={status}
                    defaultValue={"pending"}
                    onChange={(e: any) => {
                      setStatus(e.target.value);
                    }}
                    size="small"
                  >
                    {allStatus?.map(
                      (status: { name: string; value: string }) => {
                        return (
                          <MenuItem value={status.value}>
                            {status.name}
                          </MenuItem>
                        );
                      }
                    )}
                  </StadardSelect>
                </div>
                <p>
                  {" "}
                  <span>
                    {isLoading || isFetching ? <>Loading...</> : <></>}
                  </span>
                </p>
              </div>
              <div>
                {" "}
                <AddButton
                  startIcon={<MyAddIcon />}
                  onClick={() => setOpen(!open)}
                >
                  Create a request Adders
                </AddButton>
              </div>
            </div>
            <Grid item xs={12}>
              <div className="seconddiv">
                {isLoading || isFetching ? (
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      width: "100%",
                      height: "200px",
                      px: 15,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <MyTable>
                    <tr>
                      <th style={{ width: "12%" }}>id</th>
                      <th style={{ width: "23%" }}>Adder Name</th>
                      <th style={{ width: "23%" }}>installer_name</th>
                      <th style={{ width: "63%" }}>description</th>
                    </tr>
                    {adderList()}
                  </MyTable>
                )}
              </div>
            </Grid>
          </div>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: 5,

              justifyContent: "flex-end",
            }}
          >
            <Pagination
            //   count={Math.floor(data?.data?.total / 10)}
            //   page={page}
            //   onChange={handlePageChange}
            />
          </Stack>
        </Grid>
      </Grid>

      <SlidingPane children={<CreateRquest />} open={open} setOpen={setOpen} />
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .filter {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
const MyTable = styled(Table)`
  && {
  }
  th {
    &:nth-child(2) {
      text-align: left;
    }
  }
  td {
    &:nth-child(2) {
      text-align: left;
    }
  }
`;

export default RequiredAdders;
