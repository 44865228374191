import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const requiredAddersApi = createApi({
  reducerPath: "requiredAddersApi",
  baseQuery,
  tagTypes: ["RequiredAdders"],
  endpoints: (builder) => ({
    getRequiredAdders: builder.query({
      query: ({ status }) => ({
        url: `adders/requested`,
        method: "get",
        params: {
          return_all: "1",
          status,
        },
      }),
      providesTags: ["RequiredAdders"],
    }),
    getRequiredNot: builder.query({
      query: ({ page, return_all }) => ({
        url: `adders`,
        method: "get",
        params: {
          return_all,
          page,
        },
      }),
      providesTags: ["RequiredAdders"],
    }),
    // Get All Adders
    getAddars: builder.query({
      query: () => ({
        url: "adders",
        method: "get",
        params: {
          page: "1",
          return_all: "1",
        },
        providesTags: ["RequiredAdders"],
      }),
    }),
    //add adders
    createAdder: builder.mutation<any, any>({
      query: (body) => ({
        url: "/adders/create-or-edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RequiredAdders"],
    }),

    //requestNewAdders adders
    requestNewAdders: builder.mutation<any, any>({
      query: (body) => ({
        url: "/adders/request",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RequiredAdders"],
    }),
  }),
});

export const {
  useGetRequiredAddersQuery,
  useGetRequiredNotQuery,
  useCreateAdderMutation,
  useGetAddarsQuery,
  useRequestNewAddersMutation,
} = requiredAddersApi;
