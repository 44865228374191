import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import styled from "styled-components";
import { ErrorMessage } from "@hookform/error-message";

const GOOGLE_MAPS_API_KEY = "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type TLocation = {
  name: string;
  label: string;
  placeholder?: string;
  defaultValue?: string;
};
export default function GoogleMapsInput({
  name,
  label,
  placeholder,
  defaultValue,
}: TLocation) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  //   const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(getValues(name) ? [getValues(name)] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (getValues(name)) {
          newOptions = [getValues(name)];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [getValues(name), inputValue, fetch]);

  return (
    <Box sx={{ width: "100%" }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(event: any, newValue: PlaceType | null) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(name, newValue?.description, { shouldValidate: true });
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <>
                <InputLabel>{label}</InputLabel>
                <TextFieldStyle
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder={placeholder}
                />
                <ErrorMessage
                  render={({ message }) => (
                    <FormHelperText sx={{ color: "#f00" }}>
                      {message}
                    </FormHelperText>
                  )}
                  errors={errors}
                  name={name}
                />
              </>
            )}
            renderOption={(props, option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings || [];

              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{
                            fontWeight: part.highlight ? "bold" : "regular",
                          }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />
    </Box>
  );
}

const TextFieldStyle = styled(TextField)`
  && {
    margin-bottom: 1rem;
    .MuiOutlinedInput-root {
      border-radius: 0.6rem;
      width: 100%;
      .MuiOutlinedInput-input {
        border-radius: 0.8rem;
        padding: 0;
      }
    }
    border-radius: 0.8rem;
  }
`;
