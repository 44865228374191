import React from "react";
import SlidingPane from "../../../common/slidingPane";
import { Grid, MenuItem } from "@mui/material";
import styled from "styled-components";
import { MyTextFiled, StadardSelect } from "../../../common/standardSelect";
import { MyInputLable } from "../../../common/standardSelect";
import { AddButton } from "../../../common/Buttons/AddButton";
import {
  useCreateLicenseMutation,
  useGetStatesQuery,
} from "../../../../store/RTK/profile/liecense";
import { FormProvider, useForm } from "react-hook-form";
import { Tlogin } from "../../../../typs/loginType";
import { toast } from "react-toastify";
import StanderInput from "../../../common/standerInput";
import { useDispatch } from "react-redux";
import Errors from "../../../common/Errors";
import MyGoogleMaps from "../../../common/googleMaps";

type props = {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
type stateType = {
  id: number;
  abbr: string;
  address: string;
  cities: any;
  country_id: number;
  license_requirement: string;
  name: string;
  phone: string;
  url: string;
};
const AddLicenseForm: React.FC<props> = ({ open, setOpen }) => {
  const methods = useForm<Tlogin>({ mode: "onChange" });
  const [createLicense, { isLoading, isError, isSuccess }] =
    useCreateLicenseMutation();
  const { data, isLoading: stateLoading } = useGetStatesQuery("");
  const [errors, setError] = React.useState<any>("");
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [state, setState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [allLocationDetails, setAllLocationDetails] = React.useState({});
  let allStaes = data?.data?.states || [];
  const findStateDetails = (stateName: string): stateType => {
    let state = allStaes?.find((state: any) => state.abbr === stateName);
    return state;
  };

  const onSubmit = async (data: any) => {
    try {
      let newState = await findStateDetails(state);
      console.log("newState", newState);
      let state_id = newState.id;
      let postal_code = postalCode;
      await createLicense({
        ...data,
        ...coordinates,
        address,
        postalCode,
        state,
        state_id,
        postal_code,
      }).unwrap();
      if (!isError && !isLoading && isSuccess) {
        toast.success("License has been created");
      }
    } catch (error) {
      setError(error);
    }
  };
  const licenses_types = [
    { name: "Electrician", value: "electrician" },
    { name: "Roofer", value: "roofer" },
    { name: "Tree trimmer", value: "tree_trimmer" },
  ];
  return (
    <BigSection>
      {" "}
      <FormProvider {...methods}>
        <SlidingPane open={open} setOpen={setOpen} big>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} className="line">
                <h3>Add license form</h3>
              </Grid>
              <Grid item xs={12} mt={3}>
                {/* <MyInputLable>Licence number</MyInputLable> */}
                <StanderInput
                  required
                  Label="License number"
                  name="license_number"
                />{" "}
              </Grid>
              <Grid item xs={12}>
                {/* <MyInputLable>License holder</MyInputLable> */}
                <StanderInput
                  required
                  Label="License holder"
                  name="license_holder"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                {/* <MyInputLable>License Type</MyInputLable> */}
                <StanderInput
                  required
                  Label="License type"
                  name="license_type"
                  isSelect={true}
                >
                  {licenses_types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </StanderInput>
              </Grid>
              <Grid item xs={12}>
                {/* <MyInputLable>License expiration date</MyInputLable> */}
                <StanderInput
                  type="date"
                  required
                  Label="Expiration date"
                  name="expiration_date"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <MyGoogleMaps
                  setCoordinates={setCoordinates}
                  address={address}
                  setAddress={setAddress}
                  setAllLocationDetails={setAllLocationDetails}
                  setPostalCode={setPostalCode}
                  setState={setState}
                />
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  type="number"
                  required
                  Label="Max radius for jobs in miles"
                  name="max_radius_in_miles"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  type="number"
                  required
                  Label="ُExtra charges for each mile"
                  name="extra_charge_more_than_miles"
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <AddButton type="submit" fullWidth>
                  {" "}
                  {isLoading ? (
                    <span className="loading"> creating Liecense...</span>
                  ) : (
                    <> Add License</>
                  )}
                </AddButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={errors?.data?.errors}></Errors>
              </Grid>
            </Grid>
          </form>
        </SlidingPane>
      </FormProvider>
    </BigSection>
  );
};
const BigSection = styled.div`
  width: 100%;
  .icon {
    svg:first-child {
      margin-right: 5px;
    }
  }
  .container {
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    &:first-child {
      background-color: #ffffff;
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .firstdiv {
    display: flex;
    justify-content: space-between;
    padding: 1rem 24px;
    align-items: center;
  }
  .space {
    margin-top: 3rem;
  }
  .line {
    &:after {
      position: absolute;
      content: "";
      background-color: #e9ebf8;
      width: 100%;
      height: 3px;
      left: 0;
      top: 3rem;
    }
  }
  .loading {
    margin: 0 1rem;
  }
  ul {
    list-style: none;
    color: red;
  }
`;
export default AddLicenseForm;
