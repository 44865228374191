import React from "react";
import { Rating, Grid } from "@mui/material";
import styled from "styled-components";
import { Table } from "../../../style/TableStyle";

const Reviews = () => {
  const [value, setValue] = React.useState<number | null>(2);

  return (
    <Bigsection>
      <Grid container columns={12}>
        <div className="container">
          <div className="firstdiv">
            <div>
              <h4>Reviews</h4>
            </div>
            <div>
              {" "}
              {/* <FilterButton startIcon={<MyBsFilter />} onClick={handlChange}>
                Filter
              </FilterButton> */}
            </div>
          </div>
          <Grid item xs={12}>
            <div className="seconddiv">
              <Table>
                <tr>
                  <th>Name</th>
                  <th>Rating</th>
                  <th>Last Email</th>
                  <th>PM name</th>
                  <th>Phone number</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>Guy Hawkins</td>
                  <td>
                    {" "}
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </td>
                  <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                  <td>
                    <div className="success">
                      <p>
                        <span className="dot"></span> collected
                      </p>
                    </div>
                  </td>
                  <td>Earning</td>
                  <td>Date</td>
                </tr>
                <tr>
                  <td>Guy Hawkins</td>
                  <td>
                    {" "}
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </td>
                  <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                  <td>
                    <div className="success">
                      <p>
                        <span className="dot"></span> collected
                      </p>
                    </div>
                  </td>
                  <td>Earning</td>
                  <td>Date</td>
                </tr>
                <tr>
                  <td>Guy Hawkins</td>
                  <td>
                    {" "}
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </td>
                  <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                  <td>
                    <div className="success">
                      <p>
                        <span className="dot"></span> collected
                      </p>
                    </div>
                  </td>
                  <td>Earning</td>
                  <td>Date</td>
                </tr>
                <tr>
                  <td>Guy Hawkins</td>
                  <td>
                    {" "}
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </td>
                  <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                  <td>
                    <div className="success">
                      <p>
                        <span className="dot"></span> collected
                      </p>
                    </div>
                  </td>
                  <td>Earning</td>
                  <td>Date</td>
                </tr>
                <tr>
                  <td>Guy Hawkins</td>
                  <td>
                    {" "}
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </td>
                  <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
                  <td>
                    <div className="success">
                      <p>
                        <span className="dot"></span> collected
                      </p>
                    </div>
                  </td>
                  <td>Earning</td>
                  <td>Date</td>
                </tr>
              </Table>
            </div>
          </Grid>
        </div>
      </Grid>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .container {
    min-width: 100%;
  }
  .success,
  .pending {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e7d59;
    .dot {
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #28a676;
      margin-right: 8px;
    }
    p {
      background: #eaf6f1;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      font-size: 12px;
      line-height: 18px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
    }
  }
  .pending {
    color: #f79009;
    .dot {
      background-color: #f79009;
    }
    p {
      background: #fffaeb;
    }
  }
  td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #667085;
  }
`;
export default Reviews;
