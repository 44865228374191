import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    :root {
        --primary-color: #01545A;
        --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #ececec;
    --right-msg-bg: #579ffb;
    }
   * {
       margin: 0;
       padding: 0;
       outline:0;
       font-family: 'Inter', sans-serif;
       box-sizing: border-box;
       }
   body{
    transition: all 0.3s ease;
   }
   a{
    text-decoration: none;
    color: inherit;
   }
  
   
`;
