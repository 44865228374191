import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const servicesApiSLice = createApi({
  reducerPath: "service",
  baseQuery: baseQuery,
  tagTypes: ["service"],
  endpoints: (builder) => ({
    //add service
    createService: builder.mutation<any, any>({
      query: (data) => ({
        url: "/services/create",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["service"],
    }),

    //get services
    getServices: builder.query<any, any>({
      query: ({ page }) => ({
        url: "/services",
        method: "get",
        params: {
          page,
        },
      }),
      providesTags: ["service"],
    }),
  }),
});

export const { useCreateServiceMutation, useGetServicesQuery } =
  servicesApiSLice;
