import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  min-width: 100%;
  table-layout: fixed;
  tbody {
    width: 100%;
  }
  th {
    background-color: #f9fafb;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    text-align: center;

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  th,
  td {
    border-bottom: 1px solid #eaecf0;
    padding: 12px 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  color: #667085;
`;
