import React, { FC } from "react";
import { CalendarStyle } from "../../style/CalendarStyle";
import withGard from "../../utils/withGard";
import CustomeHeader from "../CustomeHeader";
import { NavLink, Outlet } from "react-router-dom";

const Index: FC = () => {
  return (
    <>
      <CalendarStyle>
        <CustomeHeader
          title="Events"
          desc="Manage your team members and their account permissions here."
        ></CustomeHeader>
        <div className="content">
          <div className="Calnedar-nav">
            <ul>
              <NavLink to="my-calendar">
                <li>Calendar</li>
              </NavLink>
              <NavLink to="working-dayes">
                <li>Working days</li>
              </NavLink>
            </ul>
          </div>
          <Outlet></Outlet>
        </div>
      </CalendarStyle>
    </>
  );
};

export default Index;
