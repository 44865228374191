import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../helper/BaseQuery";

export const profileApiSLice = createApi({
  reducerPath: "profile",
  baseQuery: baseQuery,
  tagTypes: ["profile"],
  endpoints: (builder) => ({
    // edit user
    editProfile: builder.mutation<any, any>({
      query: (data) => ({
        url: "/installers/users/edit",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["profile"],
    }),

    // edit user
    profile: builder.query<any, any>({
      query: () => ({
        url: "/installers/users/profile",
        method: "GET",
      }),
      providesTags: ["profile"],
    }),
  }),
});

export const { useEditProfileMutation, useProfileQuery } = profileApiSLice;
