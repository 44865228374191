import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { MAIN_URL } from "../config/Api";
import { RootState } from "../store/store";

export const baseQuery = fetchBaseQuery({
  baseUrl: MAIN_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.data.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    // headers.set("Content-Type", "application/json");
    // headers.set("Content-Type" , "multipart/form-data")


    return headers;
  },
});
