import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useGetSystemInfoOfJobOrderQuery } from "../../../../store/RTK/jobOrder";

const WorkOrder = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetSystemInfoOfJobOrderQuery(id);

  return (
    <Bisection>
      {isLoading ? (
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "200px",
            px: 15,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className="content">
            <div>
              <p>Number of Panels</p>
              <p>{data?.data?.panels_number}</p>
            </div>
            <div>
              <p>panels model</p>
              <p>{data.data.panels_model}</p>
            </div>
            <div>
              <p>panels wattage</p>
              <p>{data.data.panels_wattage}</p>
            </div>
            <div>
              <p>panels manufacture</p>
              <p>{data.data.panels_manufacture}</p>
            </div>
          </div>
          <hr></hr>

          <div className="content">
            <div>
              <p>new electrical panel size</p>
              <p>{data?.data?.new_electrical_panel_size}</p>
            </div>
            <div>
              <p>roof type</p>
              <p>{data?.data?.roof_type}</p>
            </div>
            <div>
              <p>runs type</p>
              <p>{data?.data?.runs_type}</p>
            </div>
            <div>
              <p>system type</p>
              <p>{data?.data?.system_type}</p>
            </div>
          </div>

          <hr></hr>

          <div className="content">
            <div>
              <p>system size</p>
              <p>{data?.data?.system_size}</p>
            </div>
            <div>
              <p>pitch degree</p>
              <p>{data.data.pitch_degree}</p>
            </div>
            <div>
              <p>racking</p>
              <p>{data.data.racking}</p>
            </div>
            <div>
              <p>optimizer</p>
              <p>{data.data.optimizer}</p>
            </div>
          </div>
          <hr></hr>
          <div className="content">
            <div>
              <p>msp</p>
              <p>{data.data.msp}</p>
            </div>
            <div>
              <p>is MPU ?</p>
              <p>{data.data.is_mpu == "1" ? "Yes" : "No"}</p>
            </div>
            <div>
              <p>inverter type</p>
              <p>{data.data.inverter_type}</p>
            </div>
            <div>
              <p>invert model</p>
              <p>{data.data.invert_model}</p>
            </div>
          </div>
          <hr></hr>
          <div className="content">
            <div>
              <p>installation type</p>
              <p>{data.data.installation_type}</p>
            </div>

            <div>
              <p> battery</p>
              <p>{data.data.battery}</p>
            </div>

            <div>
              <p>invert manufacture</p>
              <p>{data.data.invert_manufacture}</p>
            </div>

            <div>
              <p></p>
              <p></p>
            </div>
          </div>
        </>
      )}
    </Bisection>
  );
};
const Bisection = styled.div`
  padding: 1rem 0;
  .content {
    display: flex;
    gap: 1rem 0.5rem;
    div {
      position: relative;
      width: 100%;
      p:first-child {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #7a8494;
      }
      p:nth-child(2) {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #344054;
      }
      &:after {
        content: "";
        position: absolute;
        background: #e4e7ec;
        right: 0;
        top: 0;
        height: 100%;
        width: 2px;
      }
    }
  }
`;
export default WorkOrder;
