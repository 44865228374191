import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, Controller } from "react-hook-form";
import { Box, FormHelperText, InputLabel } from "@mui/material";
import styled from "styled-components";

type TDate = {
  name: string;
  defaultValue?: any;
  label?: string;
  required?: boolean;
};

const DateInput = ({ name, defaultValue, label, required }: TDate) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box width={"100%"} height={90}>
      <InputLabel
        sx={{
          color: "var(--darkGray)",
          fontSize: 14,
          fontWeight: "700",
        }}
        htmlFor={name}
        required={required}
      >
        {label}
      </InputLabel>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || null}
        render={({ field: { onChange, value } }) => (
          <Box
            sx={{
              ".MuiFormControl-root": {
                width: "100%",
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePickerStyle
                  defaultValue={defaultValue}
                  value={value}
                  onChange={(newValue) => onChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        )}
      />
      <ErrorMessage
        render={({ message }) => (
          <FormHelperText
            sx={{ color: "#f00", mt: "-1px", fontSize: 11, fontWeight: 600 }}
          >
            {message}
          </FormHelperText>
        )}
        errors={errors}
        name={name}
      />
    </Box>
  );
};

const DatePickerStyle = styled(DatePicker)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 0.5rem;
      width: 100%;
      font-weight: bold;
      fieldset {
        border-color: #9191912e;
      }
      ::placeholder {
        font-size: 10px;
      }
      .MuiOutlinedInput-input {
        padding: 9px 14px;
        ::placeholder {
          font-size: 13px;
        }
      }
    }
  }
`;

export default DateInput;
